import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Header from './../../Layout/Header1';
import Footer from './../../Layout/Footer1';
import PageTitle from './../../Layout/PageTitle';
import TabStyle1 from './../ShortCode/TabStyle1';
import ShopProductSlider from './ShopProductSlider';
import bnr1 from './../../../images/banner/bnr7.jpg';

import foundationDevJsonData from './../../../data/foundationPrograms';
import juniorDevJsonData from './../../../data/juniorDevPrograms';
import jsonData from './../../../data/seniorDevPrograms';
class ShopProductDetails extends Component {
    constructor(props) {
        super(props);
    }

    state = {
        item: {}
    };

    componentDidMount() {
        console.log("componentDidMount");
        this.getInitialState();
    }
    
    getInitialState = () => {
        var selectedType = localStorage.getItem('SelectedOptionType');
        console.log("here::::::",selectedType);
        if (selectedType === 'juniorDevSchool') {
            var selectedOption = localStorage.getItem( 'SelectedOption' ) || 1;
            console.log("selectedOption::::",selectedOption);
            juniorDevJsonData.map((item) => {
                if (item.id === parseInt(selectedOption)) {
                    console.log(item);
                    this.setState({
                        item: item,
                    });
                }
            });
            return {
                selectedOption: selectedOption
            };
        }

        if (selectedType === 'seniorDevSchool') {
            var selectedOption = localStorage.getItem( 'SelectedOption' ) || 1;
            console.log("selectedOption::::",selectedOption);
            jsonData.map((item) => {
                if (item.id === parseInt(selectedOption)) {
                    console.log(item);
                    this.setState({
                        item: item,
                    });
                }
            });
            return {
                selectedOption: selectedOption
            };
        }

        if (selectedType === 'foundationSchool') {
            var selectedOption = localStorage.getItem( 'SelectedOption' ) || 1;
            console.log("selectedOption::::",selectedOption);
            foundationDevJsonData.map((item) => {
                if (item.id === parseInt(selectedOption)) {
                    console.log(item);
                    this.setState({
                        item: item,
                    });
                }
            });
            return {
                selectedOption: selectedOption
            };
        }
    }
    
    render() {
        return (
            <>
                <Header />

                <div className="page-content bg-white">
                    {/* <!-- inner page banner --> */}
                    <div className="dlab-bnr-inr overlay-primary bg-pt" style={{ backgroundImage: "url(" + bnr1 + ")" }}>
						<PageTitle motherMenu='Program Details' activeMenu='Program Details' />
                    </div>
                    {/* <!-- inner page banner END --> */}
                    {/* <!-- contact area --> */}
                    <div className="section-full content-inner bg-white">
                        {/* <!-- Product details --> */}
                        <div className="container woo-entry">
                            <div className="row m-b30">
                                <div className="col-md-5 col-sm-5">
                                    <div className="product-gallery on-show-slider">
                                        <ShopProductSlider />
                                    </div>
                                </div>
                                <div className="col-md-7 col-sm-7 ">
                                    <div className="cart sticky-top">
                                        <div className="dlab-post-title ">
                                            <h4 className="post-title"><Link to ={"#"}>{this.state.item.type}</Link></h4>
                                            <p className="m-b10">{this.state.item.brief}</p>
                                            <div className="dlab-divider bg-gray tb15"><i className="icon-dot c-square"></i></div>
                                        </div>
                                        <div className="relative">
                                            <h3 className="m-tb10">{this.state.item.price} </h3>
                                            <div className="shop-item-rating">
                                                <span className="rating-bx">
                                                    <i className="fa fa-star mr-1"></i>
                                                    <i className="fa fa-star mr-1"></i>
                                                    <i className="fa fa-star mr-1"></i>
                                                    <i className="fa fa-star mr-1"></i>
                                                    <i className="fa fa-star mr-1"></i>
                                                </span>
                                                <span>5.0 Rating</span>
                                            </div>
                                        </div>
                                        <div className="shop-item-tage">
                                            <span>{this.state.item.title}</span>
                                        </div>
                                        <div className="dlab-divider bg-gray tb15"><i className="icon-dot c-square"></i></div>
                                        <div className="row">
                                        </div>
                                        <Link to={'./apply'} className="site-button radius-no"><i className="ti-pencil-alt"></i> Apply Now</Link>
                                    </div>
                                </div>
                            </div>
                            {/* Tabs */}
								{/* <TabStyle1 />  */}
							{/* Tabs End */ }
                            
							<div className="row">
                                <div className="col-md-12">
                                    {/* <h5 className="m-b20">Related Products</h5> */}
                                    {/* <ShopProductCarousel />  */}
                                </div>
							</div>
                        </div>
                        {/* <!-- Product details --> */}
                    </div>
                    {/* <!-- contact area  END --> */}
						{/* <OrderDetail /> */}
                </div>

                <Footer />
            </>
        )
    }
}
export default ShopProductDetails;