import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { FacebookShareButton, TwitterShareButton } from 'react-share';
import { FacebookIcon, TwitterIcon } from 'react-share';
import Header from './../../Layout/Header1';
import Footer from './../../Layout/Footer1';
import PageTitle from './../../Layout/PageTitle';	
// import {OrderDetail} from './../Login';
//Images
import bnr from './../../../images/banner/bnr4.jpg';
import jsonData from './../../../data/foundationPrograms';

class FoundationSchool extends Component {

	constructor(props) {
		super(props);
		this.state = {
			data: [],
		};
	}
	
	handleClick = (index) => {
		console.log(index);
		localStorage.setItem('SelectedOption', index);
		localStorage.setItem('SelectedOptionType', 'foundationSchool');
		//find item by id from jsonData
		let selectedItem = jsonData.find(item => item.id === index);
		localStorage.setItem('SelectedItem', JSON.stringify(selectedItem));
	};

    render() {
        return (
            <>
                <Header />
                <div className="page-content bg-white">
                    {/* <!-- inner page banner --> */}
                    <div className="dlab-bnr-inr overlay-primary bg-pt" style={{ backgroundImage: "url(" + bnr + ")" }}>
						<PageTitle motherMenu="Upcoming Programs"  activeMenu="Upcoming Programs"  />
                    </div>
                    {/* <!-- inner page banner END --> */}
                    {/* <!-- contact area --> */}
                    <div className="section-full content-inner">
                        {/* <!-- Product --> */}
						<div className="container">
							<p><h4>Foundation School Programs:</h4></p>
                            <div className="row">
								{jsonData.map((data,index)=>(
									<div className="col-lg-3 col-md-6 col-sm-6">
										<div className="item-box m-b10">
											<div className="item-img">
												<img src={data.image} alt="" />
												<div className="item-info-in">
													<ul>
														<li>
															<FacebookShareButton
																url={`https://centraltechhub.io${data.image}`}
																quote={`${data.title}\n ${data.details.description}`}
																hashtag={data.hashtags}
																className="Demo__some-network__share-button"
															>
																<FacebookIcon size={32} round />
															</FacebookShareButton>
														</li>
														{/* <li>
															<TwitterShareButton
																url={`https://centraltechhub.io${data.image}`}
																title={`${data.title}\n ${data.details.description}`}
																hashtags={data.hashtags}
																className="Demo__some-network__share-button"
															>
																<TwitterIcon size={32} round />
															</TwitterShareButton>
														</li> */}
														{/* <li><Link to={"#"}><i className="fa fa-facebook"></i></Link></li>
														<li><Link to={"#"}><i className="fa fa-twitter"></i></Link></li>
														<li><Link to={"#"}><i className="fa fa-instagram"></i></Link></li>
														<li><Link to={"#"}><i className="fa fa-linkedin"></i></Link></li> */}
													</ul>
												</div>
											</div>
											<div className="item-info text-center text-black p-a10">
											<h6 className="item-title font-weight-500"><Link onClick={() => this.handleClick(data.id) } key={index} to={{pathname: data.link, datas: data}} >{data.title}</Link></h6>
											<h7 className="item-title font-weight-500"><Link onClick={() => this.handleClick(data.id) } key={index} to={{pathname: data.link, datas: data}}>{data.level}</Link></h7>
												<ul className="item-review">
													<li>{ data.type }</li>
												</ul>
												<h4 className="item-price"><span className="text-primary">{ data.price }</span></h4>
											</div>
										</div>
									</div>
								))}
							</div>
                        </div>
                        {/* <!-- Product END --> */}
                    </div>
                    { /* <-- Icon Blog and Order detail--> */}
						{/* <OrderDetail /> */}
					{/* <-- Icon Blog and Order detail End--> */}
                </div>

                <Footer />
            </>
        )
    }
}
export default FoundationSchool;