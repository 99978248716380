import product1 from './../images/product/mobile/sd/flutter.jpg';
import product2 from './../images/product/mobile/sd/react.jpg';
import product3 from './../images/product/mobile/sd/kotlin.jpg';
import product4 from './../images/product/mobile/sd/swift.jpg';

import product5 from './../images/product/frontend/sd/react.jpg';
import product6 from './../images/product/frontend/sd/vue.jpg';
import product7 from './../images/product/frontend/sd/angular.jpg';
import productuiux from './../images/product/frontend/sd/uiux.jpg';

import product8 from './../images/product/backend/sd/dotnet.jpg';
import product9 from './../images/product/backend/sd/golang.jpg';
import product10 from './../images/product/backend/sd/nest.jpg';
import product11 from './../images/product/backend/sd/php.jpg';
import product12 from './../images/product/backend/sd/java.jpg';
import product13 from './../images/product/backend/sd/node.jpg';
import product14 from './../images/product/backend/sd/python.jpg';
import product15 from './../images/product/backend/sd/devops.jpg';

const jsonData = [
	{
		"id": 0,
	  "image": product1,
	  "title": "Mobile Application Development",
	  "type": "Cross (Android/iOS) Platform Technologies with (Flutter)",
	  "level": "Senior Developer School",
	  "price": "GH¢ 1,000",
	  "link": "/programs-details",
	  "brief": "Brief",
	  "details": {
		"description": "Apply here and join us now, https://centraltechhub.io/senior-dev-school",
		"expectation": {
		  "one": "Become An Astute CrossPlatform Mobile Engineer",
		  "two": "Develop your ideas"
		}
	  },
	  "additonal": {
		"duration": "12 Weeks",
		"location": "Central Tech Hub",
		"start": "11th April, 2022",
		"end": "12th June, 2022",
		"sessions": "Morning & Weekends",
		"Level": "Senior Developer",
		"job": "YES"
	  }
	},
	{
		"id": 1,
	  "image": product2,
	  "title": "Mobile Application Development",
	  "type": "Cross (Android/iOS) Platform Technologies with (React Native)",
	  "level": "Senior Developer School",
	  "price": "GH¢ 1,000",
	  "link": "/programs-details",
	  "details": {
		  "description": "Apply here and join us now, https://centraltechhub.io/senior-dev-school",
		  "expectation": {
			  "one": "Become An Astute CrossPlatform Mobile Engineer",
			  "two": "Develop your ideas"
		  }
	  },
	},
	{
		"id": 2,
	  "image": product3,
	  "title": "Mobile Application Development",
	  "type": "Native (Android) Platform Technologies with (Kotlin/Java)",
	  "level": "Senior Developer School",
	  "price": "GH¢ 1,499",
	  "link": "/programs-details",
	  "details": {
		  "description": "Apply here and join us now, https://centraltechhub.io/senior-dev-school",
		  "expectation": {
			  "one": "Become An Astute CrossPlatform Mobile Engineer",
			  "two": "Develop your ideas"
		  }
	  },
	},
	{
		"id": 4,
	  "image": product4,
	  "title": "Mobile Application Development",
	  "type": "Native (iOS) Platform Technologies with (Swift)",
	  "level": "Senior Developer School",
	  "price": "GH¢ 1,499",
	  "link": "/programs-details",
	  "details": {
		  "description": "Apply here and join us now, https://centraltechhub.io/senior-dev-school",
		  "expectation": {
			  "one": "Become An Astute CrossPlatform Mobile Engineer",
			  "two": "Develop your ideas"
		  }
	  },
	},
	{
		"id": 5,
	  "image": product5,
	  "title": "Web Application Development",
	  "type": "Frontend Technologies with (React.JS)",
	  "level": "Senior Developer School",
	  "price": "GH¢ 1,399",
	  "link": "/programs-details",
	  "details": {
		  "description": "Apply here and join us now, https://centraltechhub.io/senior-dev-school",
		  "expectation": {
			  "one": "Become An Astute CrossPlatform Mobile Engineer",
			  "two": "Develop your ideas"
		  }
	  },
	},
	{
		"id": 6,
	  "image": product6,
	  "title": "Web Application Development",
	  "type": "Frontend Technologies with (Vue.JS)",
	  "level": "Senior Developer School",
	  "price": "GH¢ 1,399",
	  "link": "/programs-details",
	  "details": {
		  "description": "Apply here and join us now, https://centraltechhub.io/senior-dev-school",
		  "expectation": {
			  "one": "Become An Astute CrossPlatform Mobile Engineer",
			  "two": "Develop your ideas"
		  }
	  },
	},
	{
		"id": 7,
	  "image": product7,
	  "title": "Web Application Development",
	  "type": "Frontend Technologies with (Angular.JS)",
	  "level": "Senior Developer School",
	  "price": "GH¢ 1,399",
	  "link": "/programs-details",
	  "details": {
		  "description": "Apply here and join us now, https://centraltechhub.io/senior-dev-school",
		  "expectation": {
			  "one": "Become An Astute CrossPlatform Mobile Engineer",
			  "two": "Develop your ideas"
		  }
	  },
	},
	{
		"id": 8,
	  "image": productuiux,
	  "title": "UI/UX Engineering",
	  "type": "Design Technologies with (Figma & Sketch)",
	  "level": "Senior Developer School",
	  "price": "GH¢ 799",
	  "link": "/programs-details",
	  "details": {
		  "description": "Apply here and join us now, https://centraltechhub.io/senior-dev-school",
		  "expectation": {
			  "one": "Become An Astute CrossPlatform Mobile Engineer",
			  "two": "Develop your ideas"
		  }
	  },
	},
	{
		"id": 9,
	  "image": product8,
	  "title": "Web Backend Application Development (WEB)",
	  "type": "Server Side Technologies with (.NET ASP (C#))",
	  "level": "Senior Developer School",
	  "price": "GH¢ 1,299",
	  "link": "/programs-details",
	  "details": {
		  "description": "Apply here and join us now, https://centraltechhub.io/senior-dev-school",
		  "expectation": {
			  "one": "Become An Astute CrossPlatform Mobile Engineer",
			  "two": "Develop your ideas"
		  }
	  },
	},
	{
		"id": 10,
	  "image": product9,
	  "title": "Web Backend Application Development (WEB)",
	  "type": "Server Side Technologies with (GoLang)",
	  "level": "Senior Developer School",
	  "price": "GH¢ 1,299",
	  "link": "/programs-details",
	  "details": {
		  "description": "Apply here and join us now, https://centraltechhub.io/senior-dev-school",
		  "expectation": {
			  "one": "Become An Astute CrossPlatform Mobile Engineer",
			  "two": "Develop your ideas"
		  }
	  },
	},
	{
		"id": 11,
	  "image": product10,
	  "title": "Web Backend Application Development (WEB)",
	  "type": "Server Side Technologies with (Nest.JS)",
	  "level": "Senior Developer School",
	  "price": "GH¢ 999",
	  "link": "/programs-details",
	  "details": {
		  "description": "Apply here and join us now, https://centraltechhub.io/senior-dev-school",
		  "expectation": {
			  "one": "Become An Astute CrossPlatform Mobile Engineer",
			  "two": "Develop your ideas"
		  }
	  },
	},
	{
		"id": 12,
	  "image": product11,
	  "title": "Web Backend Application Development (WEB)",
	  "type": "Server Side Technologies with (PhP on Symphony)",
	  "level": "Senior Developer School",
	  "price": "GH¢ 999",
	  "link": "/programs-details",
	  "details": {
		  "description": "Apply here and join us now, https://centraltechhub.io/senior-dev-school",
		  "expectation": {
			  "one": "Become An Astute CrossPlatform Mobile Engineer",
			  "two": "Develop your ideas"
		  }
	  },
	},
	{
		"id": 13,
	  "image": product12,
	  "title": "Web Backend Application Development (WEB)",
	  "type": "Server Side Technologies with (Java on Springboot)",
	  "level": "Senior Developer School",
	  "price": "GH¢ 1,299",
	  "link": "/programs-details",
	  "details": {
		  "description": "Apply here and join us now, https://centraltechhub.io/senior-dev-school",
		  "expectation": {
			  "one": "Become An Astute CrossPlatform Mobile Engineer",
			  "two": "Develop your ideas"
		  }
	  },
	},
	{
		"id": 14,
	  "image": product13,
	  "title": "Web Backend Application Development (WEB)",
	  "type": "Server Side Technologies with (Node.JS on Express)",
	  "level": "Senior Developer School",
	  "price": "GH¢ 999",
	  "link": "/programs-details",
	  "details": {
		  "description": "Apply here and join us now, https://centraltechhub.io/senior-dev-school",
		  "expectation": {
			  "one": "Become An Astute CrossPlatform Mobile Engineer",
			  "two": "Develop your ideas"
		  }
	  },
	},
	{
		"id": 14,
	  "image": product14,
	  "title": "Web Backend Application Development (WEB)",
	  "type": "Server Side Technologies with (Python on Django)",
	  "level": "Senior Developer School",
	  "price": "GH¢ 1,299",
	  "link": "/programs-details",
	  "details": {
		  "description": "Apply here and join us now, https://centraltechhub.io/senior-dev-school",
		  "expectation": {
			  "one": "Become An Astute CrossPlatform Mobile Engineer",
			  "two": "Develop your ideas"
		  }
	  },
	},
	{
		"id": 15,
	  "image": product15,
	  "title": "DevOps Engineering  (WEB)",
	  "type": "DevOps with (Docker,AWS, etc)",
	  "level": "Senior Developer School",
	  "price": "GH¢ 1,999",
	  "link": "/programs-details",
	  "details": {
		  "description": "Apply here and join us now, https://centraltechhub.io/senior-dev-school",
		  "expectation": {
			  "one": "Become An Astute CrossPlatform Mobile Engineer",
			  "two": "Develop your ideas"
		  }
	  },
	}
  ]

export default jsonData