import product1 from './../images/product/mobile/jd/flutter.jpg';
import product2 from './../images/product/mobile/jd/react.jpg';
import product3 from './../images/product/mobile/jd/kotlin.jpg';
import product4 from './../images/product/mobile/jd/swift.jpg';

import product5 from './../images/product/frontend/jd/react.jpg';
import product6 from './../images/product/frontend/jd/vue.jpg';
import product7 from './../images/product/frontend/jd/angular.jpg';
import productuiux from './../images/product/frontend/jd/uiux.jpg';

import product8 from './../images/product/backend/jd/dotnet.jpg';
import product9 from './../images/product/backend/jd/golang.jpg';
import product10 from './../images/product/backend/jd/nest.jpg';
import product11 from './../images/product/backend/jd/php.jpg';
import product12 from './../images/product/backend/jd/java.jpg';
import product13 from './../images/product/backend/jd/node.jpg';
import product14 from './../images/product/backend/jd/python.jpg';
import product15 from './../images/product/backend/jd/devops.jpg';

const jsonData = [
	{
		"id": 0,
		'image': product1,
		'title': 'Mobile Application Development',
		'type': 'Cross (Android/iOS) Platform Technologies with (Flutter)',
		'level': 'Junior Developer School',
		'price': 'GH¢ 799',
		'link': '/programs-details',
		"brief": "Brief",
		"details": {
			"description": "Apply here and join us now, https://centraltechhub.io/junior-dev-school",
			"expectation": {
				"one": "Become An Astute CrossPlatform Mobile Engineer",
				"two": "Develop your ideas"
			}
		},
	},
	{
		"id": 1,
		'image': product2,
		'title': 'Mobile Application Development',
		'type': 'Cross (Android/iOS) Platform Technologies with (React Native)',
		'level': 'Junior Developer School',
		'price': 'GH¢ 799',
		'link': '/programs-details',
		"details": {
			"description": "Apply here and join us now, https://centraltechhub.io/junior-dev-school",
			"expectation": {
				"one": "Become An Astute CrossPlatform Mobile Engineer",
				"two": "Develop your ideas"
			}
		},
	},
	{
		"id": 2,
		'image': product3,
		'title': 'Mobile Application Development',
		'type': 'Native (Android) Platform Technologies with (Kotlin/Java)',
		'level': 'Junior Developer School',
		'price': 'GH¢ 999',
		'link': '/programs-details',
		"details": {
			"description": "Apply here and join us now, https://centraltechhub.io/junior-dev-school",
			"expectation": {
				"one": "Become An Astute CrossPlatform Mobile Engineer",
				"two": "Develop your ideas"
			}
		},
	},
	{
		"id": 3,
		'image': product4,
		'title': 'Mobile Application Development',
		'type': 'Native (iOS) Platform Technologies with (Swift)',
		'level': 'Junior Developer School',
		'price': 'GH¢ 1,299',
		'link': '/programs-details',
		"details": {
			"description": "Apply here and join us now, https://centraltechhub.io/junior-dev-school",
			"expectation": {
				"one": "Become An Astute CrossPlatform Mobile Engineer",
				"two": "Develop your ideas"
			}
		},
	},

	{
		"id": 4,
		'image': product5,
		'title': 'Web Application Development',
		'type': 'Frontend Technologies with (React.JS)',
		'level': 'Junior Developer School',
		'price': 'GH¢ 799',
		'link': '/programs-details',
		"details": {
			"description": "Apply here and join us now, https://centraltechhub.io/junior-dev-school",
			"expectation": {
				"one": "Become An Astute CrossPlatform Mobile Engineer",
				"two": "Develop your ideas"
			}
		},
	},
	{
		"id": 5,
		'image': product6,
		'title': 'Web Application Development',
		'type': 'Frontend Technologies with (Vue.JS)',
		'level': 'Junior Developer School',
		'price': 'GH¢ 799',
		'link': '/programs-details',
		"details": {
			"description": "Apply here and join us now, https://centraltechhub.io/junior-dev-school",
			"expectation": {
				"one": "Become An Astute CrossPlatform Mobile Engineer",
				"two": "Develop your ideas"
			}
		},
	},
	{
		"id": 6,
		'image': product7,
		'title': 'Web Application Development',
		'type': 'Frontend Technologies with (Angular.JS)',
		'level': 'Junior Developer School',
		'price': 'GH¢ 799',
		'link': '/programs-details',
		"details": {
			"description": "Apply here and join us now, https://centraltechhub.io/junior-dev-school",
			"expectation": {
				"one": "Become An Astute CrossPlatform Mobile Engineer",
				"two": "Develop your ideas"
			}
		},
	},
	{
		"id": 7,
		'image': productuiux,
		'title': 'UI/UX Engineering',
		'type': 'Design Technologies with (Figma & Sketch)',
		'level': 'Junior Developer School',
		'price': 'GH¢ 799',
		'link': '/programs-details',
		"details": {
			"description": "Apply here and join us now, https://centraltechhub.io/junior-dev-school",
			"expectation": {
				"one": "Become An Astute CrossPlatform Mobile Engineer",
				"two": "Develop your ideas"
			}
		},
	},

	{ 
		"id": 8, 
		'image': product8, 
		'title': 'Web Backend Application Development (WEB)', 
		'type': 'Server Side Technologies with (.NET ASP (C#))', 
		'level': 'Junior Developer School', 
		'price': 'GH¢ 1,299', 
		'link': '/programs-details',
		"details": {
			"description": "Apply here and join us now, https://centraltechhub.io/junior-dev-school",
			"expectation": {
				"one": "Become An Astute CrossPlatform Mobile Engineer",
				"two": "Develop your ideas"
			}
		},
	},
	{ 
		"id": 9, 
		'image': product9, 
		'title': 'Web Backend Application Development (WEB)', 
		'type': 'Server Side Technologies with (GoLang)', 
		'level': 'Junior Developer School', 
		'price': 'GH¢ 1,299', 
		'link': '/programs-details',
		"details": {
			"description": "Apply here and join us now, https://centraltechhub.io/junior-dev-school",
			"expectation": {
				"one": "Become An Astute CrossPlatform Mobile Engineer",
				"two": "Develop your ideas"
			}
		}, 
	},
	{ 
		"id": 10, 
		'image': product10, 
		'title': 'Web Backend Application Development (WEB)', 
		'type': 'Server Side Technologies with (Nest.JS)', 
		'level': 'Junior Developer School', 
		'price': 'GH¢ 999', 
		'link': '/programs-details',
		"details": {
			"description": "Apply here and join us now, https://centraltechhub.io/junior-dev-school",
			"expectation": {
				"one": "Become An Astute CrossPlatform Mobile Engineer",
				"two": "Develop your ideas"
			}
		}, 
	},
	{ 
		"id": 11, 
		'image': product11, 
		'title': 'Web Backend Application Development (WEB)', 
		'type': 'Server Side Technologies with (PhP on Symphony)', 
		'level': 'Junior Developer School', 
		'price': 'GH¢ 999', 
		'link': '/programs-details',
		"details": {
			"description": "Apply here and join us now, https://centraltechhub.io/junior-dev-school",
			"expectation": {
				"one": "Become An Astute CrossPlatform Mobile Engineer",
				"two": "Develop your ideas"
			}
		}, 
	},
	{ 
		"id": 12, 
		'image': product12, 
		'title': 'Web Backend Application Development (WEB)', 
		'type': 'Server Side Technologies with (Java on Springboot)', 
		'level': 'Junior Developer School', 
		'price': 'GH¢ 1,299', 
		'link': '/programs-details' ,
		"details": {
			"description": "Apply here and join us now, https://centraltechhub.io/junior-dev-school",
			"expectation": {
				"one": "Become An Astute CrossPlatform Mobile Engineer",
				"two": "Develop your ideas"
			}
		},
	},
	{ 
		"id": 13, 
		'image': product13, 
		'title': 'Web Backend Application Development (WEB)', 
		'type': 'Server Side Technologies with (Node.JS on Express)', 
		'level': 'Junior Developer School', 
		'price': 'GH¢ 999', 
		'link': '/programs-details',
		"details": {
			"description": "Apply here and join us now, https://centraltechhub.io/junior-dev-school",
			"expectation": {
				"one": "Become An Astute CrossPlatform Mobile Engineer",
				"two": "Develop your ideas"
			}
		}, 
	},
	{ 
		"id": 14, 
		'image': product14, 
		'title': 'Web Backend Application Development (WEB)', 
		'type': 'Server Side Technologies with (Python on Django)', 
		'level': 'Junior Developer School', 
		'price': 'GH¢ 1,299', 
		'link': '/programs-details',
		"details": {
			"description": "Apply here and join us now, https://centraltechhub.io/junior-dev-school",
			"expectation": {
				"one": "Become An Astute CrossPlatform Mobile Engineer",
				"two": "Develop your ideas"
			}
		}, 
	},
	{ 
		"id": 15, 
		'image': product15, 
		'title': 'DevOps Engineering  (WEB)', 
		'type': 'DevOps', 
		'level': 'Junior Developer School', 
		'price': 'GH¢ 999', 
		'link': '/programs-details',
		"details": {
			"description": "Apply here and join us now, https://centraltechhub.io/junior-dev-school",
			"expectation": {
				"one": "Become An Astute CrossPlatform Mobile Engineer",
				"two": "Develop your ideas"
			}
		}, 
	},
]

export default jsonData