import React,{Component} from 'react';			

export default class ThemeButton extends Component{
	render(){
		return(
			<>
				<a href="tel:+233240865997" className="bt-buy-now theme-btn"><i className="ti-headphone-alt"></i><span>Call Us</span></a>
				<a href="./contact"  className="bt-support-now theme-btn"><i className="ti-themify-favicon"></i><span>Send A Message</span></a>
			</>
		)
	}	
}
