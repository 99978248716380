import React, { useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import { useHistory } from 'react-router-dom';
import Header from './../Layout/Header1';
import Footer from './../Layout/Footer1';

import bgimg from './../../images/background/bg2.jpg';

const Application = (props) => {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [age, setAge] = useState('');
    const [gender, setGender] = useState('');
    const [region, setRegion] = useState('');
    const [city, setCity] = useState('');
    const [education, setEducationLevel] = useState('');
    const [hasExperience, setHasExperience] = useState('');
    const [programe, setProgram] = useState('');
    const [accommodation, setAccommodation] = useState('');
    const [message, setMessage] = useState("");
    let history = useHistory();

    let handleSubmit = async (e) => {

        //generate a 6 digit CODE starting with "CC-"
        let code = "CC-" + Math.floor(Math.random() * 1000000);
        let program = localStorage.getItem("SelectedItem");
        
        //convert program to JSON
        program = JSON.parse(program);
        console.log(code, program);

        e.preventDefault();
        let body = JSON.stringify({
            firstName: firstName,
            lastName: lastName,
            email: email,
            age: age,
            gender: gender,
            region: region,
            city: city,
            education: education,
            hasExperience: hasExperience,
            programe: programe,
            phone: phone,
            applicantID: code,
            applicationClosingDate: "2020-06-30",
            programStartDate: "2020-5-20",
            programOrientationDate: "2020-5-20",
            programDuration: "12 Weeks",
            accommodation: accommodation,
            fee: program !== null ? program.price : "N/A",
            paymentUrl: "https://app.mybusinesspay.com/payment/p/cth-software-engineering-training",
            programImageUrl: program !== null ? `https://centraltechhub.io/${program.programImageUrl}` : "N/A",
            aboutProgram: program !== null ? program.type : "N/A",
            accommodationOptions: accommodation,
            accommodationFee: "500",
        });
        //http://localhost:3000/
        try {
            const res = await axios.post('https://code-coast-cth.herokuapp.com/applications/new', {
                firstName: firstName,
                lastName: lastName,
                email: email,
                age: age,
                gender: gender,
                region: region,
                city: city,
                education: education,
                hasExperience: hasExperience,
                program: programe,
                phone: phone,
                applicantID: code,
                applicationClosingDate: "2020-06-30",
                programStartDate: "2020-5-20",
                programOrientationDate: "2020-5-20",
                programDuration: "12 Weeks",
                accommodation: accommodation,
                fee: program !== null ? program.price : "N/A",
                paymentUrl: "https://app.mybusinesspay.com/payment/p/cth-software-engineering-training",
                programImageUrl: program !== null ? `https://centraltechhub.io/${program.programImageUrl}` : "N/A",
                aboutProgram: program !== null ? program.type : "N/A",
                accommodationOptions: accommodation,
                accommodationFee: "500",
            });
            let resJson = await res.data;
            console.log("resJson: ", resJson);
            if (res.data.message !== 'exist') {
                setFirstName('');
                setLastName('');
                setEmail('');
                setPhone('');
                setAge('');
                setGender('');
                setRegion('');
                setCity('');
                setEducationLevel('');
                setHasExperience('');
                setProgram('');
                setMessage("Your application has been submitted successfully");
                localStorage.setItem("application", body);
                const { value: accept } = await Swal.fire({
                    title: 'Successfully Applied',
                    input: 'checkbox',
                    inputValue: 1,
                    dismissOnClickOutside: false,
                    inputPlaceholder:
                        `You have successfully applied for the program. Please check your email for the application ID and the payment link.
                        click continue to proceed to the payment page.`,
                    confirmButtonText:
                      'Continue <i class="fa fa-arrow-right"></i>',
                    inputValidator: (result) => {
                      return !result && 'You need to proceed to the payment page'
                    }
                  })
                  
                  if (accept) {
                      window.location.href = "https://app.mybusinesspay.com/payment/p/cth-software-engineering-training";
                  }
            } else {
                setMessage("You Have Already Applied");
                const levelType = localStorage.getItem("SelectedOptionType");
                if (levelType === "foundationSchool") {
                    const { value: ok } = await Swal.fire({
                        title: 'Alert!',
                        text: 'You have already applied to one of our programs, if you want to change program or apply to another program, please contact us at +233595445554',
                        icon: 'info',
                        confirmButtonText: 'Ok'
                    })
                    if (ok) {
                        history.push('/foundation-school');
                    }
                }

                if (levelType === "juniorDevSchool") {
                    const { value: ok } = await Swal.fire({
                        title: 'Alert!',
                        text: 'You have already applied to one of our programs, if you want to change program or apply to another program, please contact us at +233595445554',
                        icon: 'info',
                        confirmButtonText: 'Ok'
                    })
                    if (ok) {
                        history.push('/junior-dev-school');
                    }
                }

                if (levelType === "seniorDevSchool") {
                    const { value: ok } = await Swal.fire({
                        title: 'Alert!',
                        text: 'You have already applied to one of our programs, if you want to change program or apply to another program, please contact us at +233595445554',
                        icon: 'info',
                        confirmButtonText: 'Ok'
                    })
                    if (ok) {
                        history.push('/senior-dev-school');
                    }
                }
            }
        } catch (err) {
            console.log(err);
        }
    };

    (function () {
        let application = localStorage.getItem("application");
        //console.log("application: ", application);
        let current_url = window.location.href;
        let find_matching_word = current_url.indexOf("amount");
        console.log("amount: ", find_matching_word, current_url);

        if (find_matching_word === -1) {
            let url = new URL(current_url);
            let status = url.searchParams.get("status");
            let amount = url.searchParams.get("amount");
            let payment_date = url.searchParams.get("payment_date");

            if (status === "success") {
                console.log("status: ", status, "amount: ", amount, "payment_date: ", payment_date);
                setMessage("Your payment of N" + amount + " was successful");
            }
         }
    })();

    return (
        <>
            <Header />
            <div className="full-section">
                {/* <!-- inner page banner --> */}
                <div className="dlab-bnr-inr overlay-primary-dark contact-page" style={{ backgroundImage: "url(" + bgimg + ")" }}>
                    <div className="container">
                        <div className="row">

                            <div className="col-lg-2 col-xs-4">
                                <div className="row text-white">
                                </div>
                            </div>

                            <div className="col-lg-8 col-xs-8">
                                <form onSubmit={handleSubmit} className="inquiry-form dzForm" action="#">
                                    <div className="dzFormMsg"></div>
                                    <h3 className="box-title m-t0 m-b10">Second Cohort Applications Opened <span className="bg-primary"></span></h3>
                                    <p>Join Central Tech Hub, Your journey starts today.</p>
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <div className="input-group">
                                                    <span className="input-group-addon"><i className="ti-user text-primary"></i></span>
                                                    <input value={firstName} onChange={(e) => setFirstName(e.target.value)} name="dzName" type="text" required className="form-control" placeholder="First Name" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <div className="input-group">
                                                    <span className="input-group-addon"><i className="ti-user text-primary"></i></span>
                                                    <input value={lastName} onChange={(e) => setLastName(e.target.value)} name="dzName" type="text" required className="form-control" placeholder="Last Name" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="form-group">
                                                <div className="input-group">
                                                    <span className="input-group-addon"><i className="ti-email text-primary"></i></span>
                                                    <input value={email} onChange={(e) => setEmail(e.target.value)} name="dzEmail" type="email" className="form-control" required placeholder="Your Email Id" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-7">
                                            <div className="form-group">
                                                <div className="input-group">
                                                    <span className="input-group-addon"><i className="ti-mobile text-primary"></i></span>
                                                    <input value={phone} onChange={(e) => setPhone(e.target.value)} name="dzOther[Phone]" type="text" required className="form-control" placeholder="Phone" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-7">
                                            <div className="form-group">
                                                <div className="input-group">
                                                    <span className="input-group-addon"><i className="ti-time text-primary"></i></span>
                                                    <select required value={age} onChange={(e) => setAge(e.target.value)} className="form-control">
                                                        <option>Select Age Range</option>
                                                        <option>16 years - 25 years</option>
                                                        <option>26 years - 35 years</option>
                                                        <option>36 years - 45 years</option>
                                                        <option>45 years And Above</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-7">
                                            <div className="form-group">
                                                <div className="input-group">
                                                    <span className="input-group-addon"><i className="ti-user text-primary"></i></span>
                                                    <select required value={gender} onChange={(e) => setGender(e.target.value)} className="form-control">
                                                        <option>Select Gender</option>
                                                        <option>Male</option>
                                                        <option>Female</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-7">
                                            <div className="form-group">
                                                <div className="input-group">
                                                    <span className="input-group-addon"><i className="ti-user text-primary"></i></span>
                                                    <select required value={region} onChange={(e) => setRegion(e.target.value)} className="form-control">
                                                        <option>Select Region</option>
                                                        <option>Ahafo</option>
                                                        <option>Ashanti</option>
                                                        <option>Bono East</option>
                                                        <option>Brong Ahafo</option>
                                                        <option>Central</option>
                                                        <option>Eastern</option>
                                                        <option>Greater Accra</option>
                                                        <option>North East</option>
                                                        <option>Northern</option>
                                                        <option>Oti</option>
                                                        <option>Savana</option>
                                                        <option>Upper East</option>
                                                        <option>Upper West</option>
                                                        <option>Western</option>
                                                        <option>Western North</option>
                                                        <option>Volta</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-7">
                                            <div className="form-group">
                                                <div className="input-group">
                                                    <span className="input-group-addon"><i className="ti-mobile text-primary"></i></span>
                                                    <input value={city} onChange={(e) => setCity(e.target.value)} name="dzOther[City]" type="text" required className="form-control" placeholder="City" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-7">
                                            <div className="form-group">
                                                <div className="input-group">
                                                    <span className="input-group-addon"><i className="ti-time text-primary"></i></span>
                                                    <select value={education} onChange={(e) => setEducationLevel(e.target.value)} className="form-control">
                                                        <option>Selected Education Level</option>
                                                        <option>Junior High School</option>
                                                        <option>Senior High School</option>
                                                        <option>Tertiary</option>
                                                        <option>None</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-7">
                                            <div className="form-group">
                                                <div className="input-group">
                                                    <span className="input-group-addon"><i className="ti-time text-primary"></i></span>
                                                    <select required value={hasExperience} onChange={(e) => setHasExperience(e.target.value)} className="form-control">
                                                        <option>Do you have any programming experience ?</option>
                                                        <option>Yes</option>
                                                        <option>No</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-7">
                                            <div className="form-group">
                                                <div className="input-group">
                                                    <span className="input-group-addon"><i className="ti-time text-primary"></i></span>
                                                    <select required value={programe} onChange={(e) => setProgram(e.target.value)} className="form-control">
                                                        <option>Which program do your prefer ?</option>
                                                        <option>Flutter (iOS/Android)</option>
                                                        <option>React Native (iOS/Android)</option>
                                                        <option>Swift (iOS)</option>
                                                        <option>Kotlin (Android)</option>
                                                        <option>React.JS (Web FrontEnd)</option>
                                                        <option>Node.JS (Server Side)</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-7">
                                            <div className="form-group">
                                                <div className="input-group">
                                                    <span className="input-group-addon"><i className="ti-time text-primary"></i></span>
                                                    <select required value={accommodation} onChange={(e) => setAccommodation(e.target.value)} className="form-control">
                                                        <option>Will You Need Accommodation ?</option>
                                                        <option>Yes, I Need Accommodation</option>
                                                        <option>No, I Already Have Accommodation</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <button name="submit" type="submit" value="Submit" className="site-button button-lg"> <span>Submit Application</span> </button>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="form-group">
                                                <div className="input-group">
                                                    <div className="message">{message ? <p>{message}</p> : null}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>

                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- inner page banner END --> */}
            </div>

            <Footer />
        </>
    )
}
export default Application;