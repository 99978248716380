import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Header from './../../Layout/Header1';
import Footer from './../../Layout/Footer1';
import PageTitle from './../../Layout/PageTitle';
import SectionCounter from './../../Element/SectionCounter';
import TestimonialCarousel from './../../Element/TestimonialCarousel';
import FormStyle from './../../Element/FormStyle';

//Images
import bnr5 from './../../../images/banner/bnr5.jpg';
import bgmap from './../../../images/background/bg-map.jpg';
import bg1 from './../../../images/background/bg1.jpg';

const iconBox = [
	{icon: <i className="flaticon-devices" />, title:'Idea Validation & Rapid Prototyping', desc: 'Great inventions and innovations comes from ideas, we help you with rapid prototyping of your ideas then validate is sustainability and validity.'},
	{icon: <i className="flaticon-pen" />, title:'Mobile/Web/Ai Development Training', desc: 'We equip you with best practices and rich knowledge in most efficient development languages to help you ship quality and affordable softwares.'},
	{icon: <i className="flaticon-bar-chart" />, title:'Ethical Hacking & Blockchain Training', desc: 'We are ready to equip you with practical training in Ethical hacking and Blockchain development to build and maintain world class standards.'},
	{icon: <i className="flaticon-file" />, title:'AR/VR/MR/3D & Movie Animation Training', desc: 'Building engineers, architects and world class movie lovers, code coast is the place to equip you with the skill to design and animate your stories.'},
	{icon: <i className="flaticon-notebook" />, title:'AR/VR/MR/3D & Movie Animation Training', desc: 'Building engineers, architects and world class movie lovers, code coast is the place to equip you with the skill to design and animate your stories.'},
	{icon: <i className="flaticon-team" />, title:'Incubation/Startup & Entrepreneurship', desc: 'Be your own boss and impact lives as an entrepreneur, join apply to any of our ongoing programs with respect to the area of your choice.'},
];

class Service extends Component {


    render() {
        return (
            <>
                <Header />

                <div className="page-content bg-white">
                    {/* <!-- inner page banner --> */}
                    <div className="dlab-bnr-inr overlay-primary" style={{ backgroundImage: "url(" + bnr5 + ")" }}>
						<PageTitle motherMenu='Our Services'  activeMenu='Our Services' />
                    </div>
                    {/* <!-- inner page banner END --> */}
                    <div className="content-block">
                        {/* <!-- About Us --> */}
                        <div className="section-full content-inner">
                            <div className="container">
                                <div className="section-head text-black text-center">
                                    <h4 className="text-gray-dark m-b10">Our Services</h4>
                                    <h2 className="box-title m-tb0">Software & Business Development Services<span className="bg-primary"></span></h2>
                                    <p>Where we train, mentor and journey with you through the best practices of successful software business development services.</p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row ">
									{iconBox.map((data, index)=>(
										<div className="col-md-4 col-sm-6 m-b30 "  key={index}>
											<div className="icon-bx-wraper expertise  bx-style-1 p-a30 center">
												<div className="icon-lg m-b20"> <Link to={"#"} className="icon-cell">{data.icon}</Link> </div>
												<div className="icon-content">
													<h5 className="dlab-tilte text-uppercase"><Link to={"#"}>{data.title}</Link></h5>
                                                    <p>{ data.desc }</p>
												</div>
											</div>
										</div>
									))}	
                                </div>
                            </div>
                        </div>
                        {/* <!-- Our Services --> */}
                        {/* <!-- Why Chose Us --> */}
							<SectionCounter />
                        {/* <!-- Testimonials --> */}
                        <div className="section-full content-inner "   style={{ backgroundImage: "url(" + bgmap + ")", backgroundPosition: "center", backgroundRepeat: "no-repeat" }}>
                            <div className="container">
                                <div className="section-head text-center">
                                    <h2 className="box-title m-tb0">Our Testimonials<span className="bg-primary"></span></h2>
                                    <p> Here is what they say about Code Coast - The Central Tech Hub </p>
                                </div>
                                <div className="section-content m-b30 ">
									<TestimonialCarousel /> 
                                </div>
                            </div>
                        </div>
                        {/* <!-- Testimonials END --> */}
                        {/* <!-- Get in touch --> */}
                        <div className="section-full overlay-primary-dark bg-img-fix" style={{ backgroundImage: "url(" + bg1 + ")" }}>
                            <FormStyle />
                        </div>
                        {/* <!-- Get in touch --> */}
                    </div>
                    {/* <!-- contact area END --> */}
                </div>

                <Footer />
            </>
        )
    }
}
export default Service;