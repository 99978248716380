import product1 from './../images/product/mobile/fs/flutter.jpg';
import product2 from './../images/product/mobile/fs/react.jpg';
import product3 from './../images/product/mobile/fs/kotlin.jpg';
import product4 from './../images/product/mobile/fs/swift.jpg';

import product5 from './../images/product/frontend/fs/react.jpg';
import product6 from './../images/product/frontend/fs/vue.jpg';
import product7 from './../images/product/frontend/fs/angular.jpg';
import productuiux from './../images/product/frontend/fs/uiux.jpg';

import product8 from './../images/product/backend/fs/dotnet.jpg';
import product9 from './../images/product/backend/fs/golang.jpg';
import product10 from './../images/product/backend/fs/nest.jpg';
import product11 from './../images/product/backend/fs/php.jpg';
import product12 from './../images/product/backend/fs/java.jpg';
import product13 from './../images/product/backend/fs/node.jpg';
import product14 from './../images/product/backend/fs/python.jpg';
import product15 from './../images/product/backend/fs/devops.jpg';

const foundationDevJsonData = [
	{
		"id": 0,
		image: product1,
		title: 'Mobile Application Development',
		type: 'Cross (Android/iOS) Platform Technologies with (Flutter)',
		level: 'Foundation School',
		price: 'GH¢ 500',
		link: '/programs-details',
		applicationClosingDate: "24-04-2022",
		programStartDate: "25-04-2022",
		programOrientationDate: "25-04-2022",
		programDuration: "3 months",
		paymentUrl: "N/A",
		programImageUrl: product1,
		accommodationOptions: "Yes",
		accommodationFee: "GH¢ 500",
		"brief": `Flutter is an open-source UI software development kit created by Google. It is used to develop cross platform applications for Android, iOS, Linux, macOS, Windows, Google Fuchsia, and the web from a single codebase.`,
		"details": {
		  "description": `Flutter is an open-source UI software development kit created by Google. It is used to develop cross platform applications for Android, iOS, Linux, macOS, Windows, Google Fuchsia, and the web from a single codebase.`,
		  "expectation": {
			"one": "Become An Astute CrossPlatform Mobile Engineer",
			"two": "Develop your ideas"
		  }
		},
		"additonal": {
		  "duration": "12 Weeks",
		  "location": "Central Tech Hub",
		  "start": "11th April, 2022",
		  "end": "12th June, 2022",
		  "sessions": "Morning & Weekends",
		  "Level": "Foundation School",
		  "job": "YES"
		},
		hashtags: ["#flutter", "#crossplatform", "#ios", "#android", "#foundationschool"],
	},
	{
		"id": 1,
		image: product2,
		title: 'Mobile Application Development',
		type: 'Cross (Android/iOS) Platform Technologies with (React Native)',
		level: 'Foundation School',
		price: 'GH¢ 500',
		link: '/programs-details',
		applicationClosingDate: "24-04-2022",
		programStartDate: "25-04-2022",
		programOrientationDate: "25-04-2022",
		programDuration: "3 months",
		paymentUrl: "N/A",
		programImageUrl: product1,
		accommodationOptions: "Yes",
		accommodationFee: "GH¢ 500",
		"brief": "Brief",
		"details": {
		  "description": "Apply here and join us now, https://centraltechhub.io/foundation-school",
		  "expectation": {
			"one": "Become An Astute CrossPlatform Mobile Engineer",
			"two": "Develop your ideas"
		  }
		},
		"additonal": {
		  "duration": "12 Weeks",
		  "location": "Central Tech Hub",
		  "start": "11th April, 2022",
		  "end": "12th June, 2022",
		  "sessions": "Morning & Weekends",
		  "Level": "Foundation School",
		  "job": "YES"
		},
		hashtags: "#flutter #crossplatform #ios #android #foundationschool"
	},
	{
		"id": 2,
		image: product3,
		title: 'Mobile Application Development',
		type: 'Native (Android) Platform Technologies with (Kotlin/Java)',
		level: 'Foundation School',
		price: 'GH¢ 800',
		link: '/programs-details',
		applicationClosingDate: "24-04-2022",
		programStartDate: "25-04-2022",
		programOrientationDate: "25-04-2022",
		programDuration: "3 months",
		paymentUrl: "N/A",
		programImageUrl: product1,
		accommodationOptions: "Yes",
		accommodationFee: "GH¢ 500",
		"brief": "Brief",
		"details": {
		  "description": "Apply here and join us now, https://centraltechhub.io/foundation-school",
		  "expectation": {
			"one": "Become An Astute CrossPlatform Mobile Engineer",
			"two": "Develop your ideas"
		  }
		},
		"additonal": {
		  "duration": "12 Weeks",
		  "location": "Central Tech Hub",
		  "start": "11th April, 2022",
		  "end": "12th June, 2022",
		  "sessions": "Morning & Weekends",
		  "Level": "Foundation School",
		  "job": "YES"
		},
		hashtags: "#flutter #crossplatform #ios #android #foundationschool"
	},
	{
		"id": 3,
		image: product4,
		title: 'Mobile Application Development',
		type: 'Native (iOS) Platform Technologies with (Swift)',
		level: 'Foundation School',
		price: 'GH¢ 500',
		link: '/programs-details',
		applicationClosingDate: "24-04-2022",
		programStartDate: "25-04-2022",
		programOrientationDate: "25-04-2022",
		programDuration: "3 months",
		paymentUrl: "N/A",
		programImageUrl: product1,
		accommodationOptions: "Yes",
		accommodationFee: "GH¢ 500",
		"brief": "Brief",
		"details": {
		  "description": "Apply here and join us now, https://centraltechhub.io/foundation-school",
		  "expectation": {
			"one": "Become An Astute CrossPlatform Mobile Engineer",
			"two": "Develop your ideas"
		  }
		},
		"additonal": {
		  "duration": "12 Weeks",
		  "location": "Central Tech Hub",
		  "start": "11th April, 2022",
		  "end": "12th June, 2022",
		  "sessions": "Morning & Weekends",
		  "Level": "Foundation School",
		  "job": "YES"
		},
		hashtags: "#flutter #crossplatform #ios #android #foundationschool"
	},
	
	{
		"id": 4,
		image: product5,
		title: 'Web Application Development',
		type: 'Frontend Technologies with (React.JS)',
		level: 'Foundation School',
		price: 'GH¢ 600',
		link: '/programs-details',
		applicationClosingDate: "24-04-2022",
		programStartDate: "25-04-2022",
		programOrientationDate: "25-04-2022",
		programDuration: "3 months",
		paymentUrl: "N/A",
		programImageUrl: product1,
		accommodationOptions: "Yes",
		accommodationFee: "GH¢ 500",
		"brief": "Brief",
		"details": {
		  "description": `Apply here and join us now, https://centraltechhub.io/foundation-school`,
		  "expectation": {
			"one": "Become An Astute CrossPlatform Mobile Engineer",
			"two": "Develop your ideas"
		  }
		},
		"additonal": {
		  "duration": "12 Weeks",
		  "location": "Central Tech Hub",
		  "start": "11th April, 2022",
		  "end": "12th June, 2022",
		  "sessions": "Morning & Weekends",
		  "Level": "Foundation School",
		  "job": "YES"
		},
		hashtags: "#flutter #crossplatform #ios #android #foundationschool"
	},
	{
		"id": 5,
		image: product6,
		title: 'Web Application Development',
		type: 'Frontend Technologies with (Vue.JS)',
		level: 'Foundation School',
		price: 'GH¢ 600',
		link: '/programs-details',
		applicationClosingDate: "24-04-2022",
		programStartDate: "25-04-2022",
		programOrientationDate: "25-04-2022",
		programDuration: "3 months",
		paymentUrl: "N/A",
		programImageUrl: product1,
		accommodationOptions: "Yes",
		accommodationFee: "GH¢ 500",
		"brief": "Brief",
		"details": {
		  "description": "Apply here and join us now, https://centraltechhub.io/foundation-school",
		  "expectation": {
			"one": "Become An Astute CrossPlatform Mobile Engineer",
			"two": "Develop your ideas"
		  }
		},
		"additonal": {
		  "duration": "12 Weeks",
		  "location": "Central Tech Hub",
		  "start": "11th April, 2022",
		  "end": "12th June, 2022",
		  "sessions": "Morning & Weekends",
		  "Level": "Foundation School",
		  "job": "YES"
		},
		hashtags: "#flutter #crossplatform #ios #android #foundationschool"
	},
	{
		"id": 6,
		image: product7,
		title: 'Web Application Development',
		type: 'Frontend Technologies with (Angular.JS)',
		level: 'Foundation School',
		price: 'GH¢ 700',
		link: '/programs-details',
		"brief": "Brief",
		"details": {
		  "description": "Apply here and join us now, https://centraltechhub.io/foundation-school",
		  "expectation": {
			"one": "Become An Astute CrossPlatform Mobile Engineer",
			"two": "Develop your ideas"
		  }
		},
		"additonal": {
		  "duration": "12 Weeks",
		  "location": "Central Tech Hub",
		  "start": "11th April, 2022",
		  "end": "12th June, 2022",
		  "sessions": "Morning & Weekends",
		  "Level": "Foundation School",
		  "job": "YES"
		},
		hashtags: "#flutter #crossplatform #ios #android #foundationschool"
	},
	{
		"id": 7,
		image: productuiux,
		title: 'UI/UX Engineering',
		type: 'Design Technologies with (Figma & Sketch)',
		level: 'Foundation School',
		price: 'GH¢ 600',
		link: '/programs-details',
		"brief": "Brief",
		"details": {
		  "description": "Apply here and join us now, https://centraltechhub.io/foundation-school",
		  "expectation": {
			"one": "Become An Astute CrossPlatform Mobile Engineer",
			"two": "Develop your ideas"
		  }
		},
		"additonal": {
		  "duration": "12 Weeks",
		  "location": "Central Tech Hub",
		  "start": "11th April, 2022",
		  "end": "12th June, 2022",
		  "sessions": "Morning & Weekends",
		  "Level": "Foundation School",
		  "job": "YES"
		},
		hashtags: "#flutter #crossplatform #ios #android #foundationschool"
	},
	
	{
		"id": 8,
		image: product8,
		title: 'Web Backend Application Development (WEB)',
		type: 'Server Side Technologies with (.NET ASP (C#))',
		level: 'Foundation School',
		price: 'GH¢ 800',
		link: '/programs-details',
		"brief": "Brief",
		"details": {
		  "description": "Apply here and join us now, https://centraltechhub.io/foundation-school",
		  "expectation": {
			"one": "Become An Astute CrossPlatform Mobile Engineer",
			"two": "Develop your ideas"
		  }
		},
		"additonal": {
		  "duration": "12 Weeks",
		  "location": "Central Tech Hub",
		  "start": "11th April, 2022",
		  "end": "12th June, 2022",
		  "sessions": "Morning & Weekends",
		  "Level": "Foundation School",
		  "job": "YES"
		},
		hashtags: "#flutter #crossplatform #ios #android #foundationschool"
	},
	{
		"id": 9,
		image: product9,
		title: 'Web Backend Application Development (WEB)',
		type: 'Server Side Technologies with (GoLang)',
		level: 'Foundation School',
		price: 'GH¢ 800',
		link: '/programs-details',
		"brief": "Brief",
		"details": {
		  "description": "Apply here and join us now, https://centraltechhub.io/foundation-school",
		  "expectation": {
			"one": "Become An Astute CrossPlatform Mobile Engineer",
			"two": "Develop your ideas"
		  }
		},
		"additonal": {
		  "duration": "12 Weeks",
		  "location": "Central Tech Hub",
		  "start": "11th April, 2022",
		  "end": "12th June, 2022",
		  "sessions": "Morning & Weekends",
		  "Level": "Foundation School",
		  "job": "YES"
		},
		hashtags: "#flutter #crossplatform #ios #android #foundationschool"
	},
	{
		"id": 10,
		image: product10,
		title: 'Web Backend Application Development (WEB)',
		type: 'Server Side Technologies with (Nest.JS)',
		level: 'Foundation School',
		price: 'GH¢ 600',
		link: '/programs-details',
		"brief": "Brief",
		"details": {
		  "description": "Apply here and join us now, https://centraltechhub.io/foundation-school",
		  "expectation": {
			"one": "Become An Astute CrossPlatform Mobile Engineer",
			"two": "Develop your ideas"
		  }
		},
		"additonal": {
		  "duration": "12 Weeks",
		  "location": "Central Tech Hub",
		  "start": "11th April, 2022",
		  "end": "12th June, 2022",
		  "sessions": "Morning & Weekends",
		  "Level": "Foundation School",
		  "job": "YES"
		},
		hashtags: "#flutter #crossplatform #ios #android #foundationschool"
	},
	{
		"id": 11,
		image: product11,
		title: 'Web Backend Application Development (WEB)',
		type: 'Server Side Technologies with (PhP on Symphony)',
		level: 'Foundation School',
		price: 'GH¢ 700',
		link: '/programs-details',
		"brief": "Brief",
		"details": {
		  "description": "Apply here and join us now, https://centraltechhub.io/foundation-school",
		  "expectation": {
			"one": "Become An Astute CrossPlatform Mobile Engineer",
			"two": "Develop your ideas"
		  }
		},
		"additonal": {
		  "duration": "12 Weeks",
		  "location": "Central Tech Hub",
		  "start": "11th April, 2022",
		  "end": "12th June, 2022",
		  "sessions": "Morning & Weekends",
		  "Level": "Foundation School",
		  "job": "YES"
		},
		hashtags: "#flutter #crossplatform #ios #android #foundationschool"
	},
	{
		"id": 12,
		image: product12, 
		title: 'Web Backend Application Development (WEB)', 
		type: 'Server Side Technologies with (Java on Springboot)', 
		level: 'Foundation School', 
		price: 'GH¢ 800', 
		link: '/programs-details',
		"brief": "Brief",
		"details": {
		  "description": "Apply here and join us now, https://centraltechhub.io/foundation-school",
		  "expectation": {
			"one": "Become An Astute CrossPlatform Mobile Engineer",
			"two": "Develop your ideas"
		  }
		},
		"additonal": {
		  "duration": "12 Weeks",
		  "location": "Central Tech Hub",
		  "start": "11th April, 2022",
		  "end": "12th June, 2022",
		  "sessions": "Morning & Weekends",
		  "Level": "Foundation School",
		  "job": "YES"
		},
		hashtags: "#flutter #crossplatform #ios #android #foundationschool"
	},
	{
		"id": 13, 
		image: product13, 
		title:'Web Backend Application Development (WEB)', 
		type: 'Server Side Technologies with (Node.JS on Express)', 
		level: 'Foundation School', 
		price: 'GH¢ 600', 
		link: '/programs-details',
		"brief": "Brief",
		"details": {
		  "description": "Apply here and join us now, https://centraltechhub.io/foundation-school",
		  "expectation": {
			"one": "Become An Astute CrossPlatform Mobile Engineer",
			"two": "Develop your ideas"
		  }
		},
		"additonal": {
		  "duration": "12 Weeks",
		  "location": "Central Tech Hub",
		  "start": "11th April, 2022",
		  "end": "12th June, 2022",
		  "sessions": "Morning & Weekends",
		  "Level": "Foundation School",
		  "job": "YES"
		},
		hashtags: "#flutter #crossplatform #ios #android #foundationschool"
	},
	{
		"id": 14,  
		image: product14, 
		title: 'Web Backend Application Development (WEB)', 
		type: 'Server Side Technologies with (Python on Django)', 
		level: 'Foundation School', 
		price: 'GH¢ 700', 
		link: '/programs-details',
		"brief": "Brief",
		"details": {
		  "description": "Apply here and join us now, https://centraltechhub.io/foundation-school",
		  "expectation": {
			"one": "Become An Astute CrossPlatform Mobile Engineer",
			"two": "Develop your ideas"
		  }
		},
		"additonal": {
		  "duration": "12 Weeks",
		  "location": "Central Tech Hub",
		  "start": "11th April, 2022",
		  "end": "12th June, 2022",
		  "sessions": "Morning & Weekends",
		  "Level": "Foundation School",
		  "job": "YES"
		},
		hashtags: "#flutter #crossplatform #ios #android #foundationschool"
	},
	{	"id": 15, 
		image: product15, 
		title:'DevOps Engineering (WEB)', 
		type: 'DevOps', 
		level: 'Foundation School', 
		price:'GH¢ 799', 
		link: '/programs-details',
		"brief": "Brief",
		"details": {
		  "description": "Apply here and join us now, https://centraltechhub.io/foundation-school",
		  "expectation": {
			"one": "Become An Astute CrossPlatform Mobile Engineer",
			"two": "Develop your ideas"
		  }
		},
		"additonal": {
		  "duration": "12 Weeks",
		  "location": "Central Tech Hub",
		  "start": "11th April, 2022",
		  "end": "12th June, 2022",
		  "sessions": "Morning & Weekends",
		  "Level": "Foundation School",
		  "job": "YES"
		},
		hashtags: "#flutter #crossplatform #ios #android #foundationschool"
	},
];

export default foundationDevJsonData