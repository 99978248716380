import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Header from './../../Layout/Header1';
import Footer from './../../Layout/Footer1';
import PageTitle from './../../Layout/PageTitle';	
// import {OrderDetail} from './../Login';
//Images
import bnr from './../../../images/banner/bnr4.jpg';
import product1 from './../../../images/product/item1.jpg';
import product2 from './../../../images/product/item2.jpg';
import product3 from './../../../images/product/item3.jpg';
import product4 from './../../../images/product/item4.jpg';
import product5 from './../../../images/product/item5.jpg';
import product6 from './../../../images/product/item6.jpg';
// import product7 from './../../../images/product/item7.jpg';
// import product8 from './../../../images/product/item8.jpg';

const specialProgramsItemBox=[
	{image: product1, title:'Digital Marketing', type: 'Complete Digital Marketing Program', level: 'Complete', price:'GH¢ 1,000', link:'/programs-details'},
	{image: product2, title:'Animation', type: 'Character Animation', level: 'Complete', price:'GH¢ 1,499', link:'/programs-details'},
	{image: product3, title:'Graphics Design', type: 'Motion Graphics, Logo Design, Conceptual Graphics', level: 'Complete', price:'GH¢ 1,000', link:'/programs-details'},
	{image: product4, title:'Videography', type: 'Music Video Shoot, Movie Shoot, Video Directing', level: 'Complete', price:'GH¢ 1,000', link:'/programs-details'},
	{image: product5, title: 'Photography', type: 'Complete Photography Program', level: 'Complete', price: 'GH¢ 1,000', link: '/programs-details' },
	{image: product6, title:'IoT', type: 'Robotics For Kids', level: 'Kids', price:'GH¢ 500', link:'/programs-details'},
];

class SpecialPrograms extends Component {
    render() {
        return (
            <>
                <Header />
                <div className="page-content bg-white">
                    {/* <!-- inner page banner --> */}
                    <div className="dlab-bnr-inr overlay-primary bg-pt" style={{ backgroundImage: "url(" + bnr + ")" }}>
						<PageTitle motherMenu="Upcoming Programs"  activeMenu="Upcoming Programs"  />
                    </div>
                    {/* <!-- inner page banner END --> */}
                    {/* <!-- contact area --> */}
                    <div className="section-full content-inner">
                        {/* <!-- Product --> */}
						<div className="container">
							<p><h4>Special Programs:</h4></p>
							<div className="row">
							{specialProgramsItemBox.map((data,index)=>(
									<div className="col-lg-3 col-md-6 col-sm-6">
										<div className="item-box m-b10">
											<div className="item-img">
												<img src={data.image} alt="" />
												<div className="item-info-in">
													<ul>
														<li><Link to={"#"}><i className="fa fa-facebook"></i></Link></li>
														<li><Link to={"#"}><i className="fa fa-twitter"></i></Link></li>
														<li><Link to={"#"}><i className="fa fa-instagram"></i></Link></li>
														<li><Link to={"#"}><i className="fa fa-linkedin"></i></Link></li>
													</ul>
												</div>
											</div>
											<div className="item-info text-center text-black p-a10">
												<h6 className="item-title font-weight-500"><Link to={ data.link }>{data.title}</Link></h6>
												<h7 className="item-title font-weight-500"><Link to={ data.link }>{data.level}</Link></h7>
												<ul className="item-review">
													<li>{ data.type }</li>
												</ul>
												<h4 className="item-price"><span className="text-primary">{ data.price }</span></h4>
											</div>
										</div>
									</div>
								))}
							</div>
                        </div>
                        {/* <!-- Product END --> */}
                    </div>
                    { /* <-- Icon Blog and Order detail--> */}
						{/* <OrderDetail /> */}
					{/* <-- Icon Blog and Order detail End--> */}
                </div>

                <Footer />
            </>
        )
    }
}
export default SpecialPrograms;