import React,{Fragment,  useState} from 'react';
import {Link} from 'react-router-dom';
import { TabContent, TabPane, } from 'reactstrap';
import classnames from 'classnames';

//Images
import pic5 from './../../images/about/pic5.jpg';
import pic6 from './../../images/about/pic6.jpg';
import pic7 from './../../images/about/pic7.jpg';
import pic8 from './../../images/about/pic8.jpg';

function Para(){
	return(
		<>
			<div className="abuot-box p-lr30">
				<p>Incoming software engineers are required to pay at least 40% of total programs fee before the commencement of the programs,
					The remaining 60% is spread across the next 3-months at 20% per month respectively.
					<p><b>Code Coast - CTH Scholarship: </b> This type of scholarship is only available to level 2 and 3, if the company
					at it own discretion deems it fit to give a credit based scholarship.</p>
					<p><b>Third Party Scholarship: </b>Incoming software developers are eligible to start the program at either level with 3rd party scholarships.</p>
				</p>
			</div>	
		</>
	)
}

function Para2(){
	return(
		<>
			<div className="abuot-box p-lr30">
				<p>Our programs are designed to help you get the most out of your career, so we have well curated sessions for all our prospective software developers.
					We have a dedicated team of mentors who are experts in their respective fields and are ready to help you with your career journey.
					time to time we have a special session for our students who are looking for a job in the industry.
					<p>~ Join us and get the best out of your career.</p>
				</p>
			</div>	
		</>
	)
}

function Para3(){
	return(
		<>
			<div className="abuot-box p-lr30">
				<p>We train you in the most relevant technologies and tools to help you get the most out of your career.
					We choose the most popular technologies in the industry and we have a dedicated team of experts who are ready to help you with your career journey.
				</p>
			</div>	
		</>
	)
}

function Para4(){
	return(
		<>
			<div className="abuot-box p-lr30">
				<p>
					Become part of our premium code coast community and get the best out of your career.
					Meet, connect and learn with other developers who are working in the industry.
					we have an interesting and unique community of developers who are working in the industry and are ready to help you with your career journey.
					you will be part of the end of month premium meetup.
					<p>~ Here we learn, connect and <b>party</b></p>
				</p>
			</div>	
		</>
	)
}

const HomeTab = (props) => {
	const [activeTab, setActiveTab] = useState('1');

    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    }
	  
	return(
		<Fragment>			
			<div className="container-fluid">
				<div className="dlab-tabs choseus-tabs">
					<ul className="nav row justify-content-center"  id="myTab" role="tablist">
						{/* <li>
							<a className= {classnames({ active : activeTab === '1' }) + ' className if avialble'}  onClick={() => { toggle('1'); }}	
							><span className="title-head">Cost Effectiveness</span></a>
						</li> */}
						<li>
							<Link to ={"#"} className= {classnames({ active : activeTab === '1'}) + ''} onClick={() => { toggle('1'); }}><span className="title-head">Cost Effectiveness</span></Link>
						</li>
						<li>
							<Link to ={"#"}	className={classnames({active:activeTab === '2'}) + ''} onClick={()=>{ toggle('2');}}><span className="title-head">Flexible Engagement Models</span></Link>
						</li>
						<li>
							<Link to ={"#"}	className={classnames({active:activeTab === '3'}) + ''} onClick={()=>{ toggle('3');}}><span className="title-head">Technology Expertization</span></Link>
						</li>
						<li>
							<Link to ={"#"}	className={classnames({active:activeTab === '4'}) + ''} onClick={()=>{ toggle('4');}}><span className="title-head">Code Coast Community</span></Link>
						</li>
					</ul>
				</div>	
			</div>
			<div className="container">
				<div className="tab-content chosesus-content">
					<div id="cost" className="tab-pane active">
						<TabContent activeTab={activeTab}>
							<TabPane tabId="1">
								<div className="row p-tb50">
									<div className="col-lg-6 m-b10 col-md-4 about-img " >
										<img src={pic5} alt="" />
									</div>
									<div className="col-lg-6 col-md-8 text-white">
										<div className="p-l30 " >
											<h4 className="font-weight-300">Programs Cost Effectiveness</h4>
											<ul className="list-checked primary">
												<li><span>Our programs quality but are of lower prices</span></li>
												<li><span>We have a payment plan for everyone</span></li>
												<li><span>Pay comfortably</span></li>
											</ul>
										</div>
										<Para />
									</div>
								</div>
							</TabPane>
							<TabPane tabId="2">
								<div className="row p-tb50">
									<div className="col-lg-6 col-md-4 about-img">
										<img src={pic6} alt="" />
									</div>
									<div className="col-lg-6 col-md-8 text-white">
										<div className="p-l30">
											<h4 className="font-weight-300">Flexible Engagement Models</h4>
											<ul className="list-checked primary">
												<li><span>Mornings: Mondays, Tuesdays, Fridays - 9:00am - 3:00pm</span></li>
												<li><span>Evenings: Mondays - Thursdays - 6:00pm - 8:30pm</span></li>
												<li><span>Weekends: Fridays - 6:00pm, Saturdays - 9:00am - 3:00pm</span></li>
											</ul>
										</div>
										<Para2 />
									</div>
								</div>
							</TabPane>
							<TabPane tabId="3">
								<div className="row p-tb50">
									<div className="col-lg-6 col-md-4 about-img">
										<img src={pic7} alt="" />
									</div>
									<div className="col-lg-6 col-md-8 text-white">
										<div className="p-l30">
											<h4 className="font-weight-300">Technology Expertization</h4>
											<ul className="list-checked primary">
												<li><span>Mobile Development Technologies</span></li>
												<li><span>Web Development Technologies</span></li>
												<li><span>Digital Media</span></li>
												<li><span>blockchain Development Technologies</span></li>
												<li><span>VR,AR,METAVERSE Development Technologies</span></li>
											</ul>
										</div>
										<Para3 />
									</div>
								</div>
							</TabPane>
							<TabPane tabId="4">
								<div className="row p-tb50">
									<div className="col-lg-6 col-md-4 about-img">
										<img src={pic8} alt="" />
									</div>
									<div className="col-lg-6 col-md-8 text-white">
										<div className="p-l30">
											<h4 className="font-weight-300">Code Coast Connect</h4>
											<ul className="list-checked primary">
												<li><span>Become part of our premium community</span></li>
												<li><span>Meet, Connect and Engage with premium mentors</span></li>
												<li><span>End of month premium meetup</span></li>
											</ul>
										</div>
										<Para4 />
									</div>
								</div>
							</TabPane>
							
						</TabContent>
					</div>	
				</div>
			</div>	
		</Fragment>
	)
	
}	
export default HomeTab;