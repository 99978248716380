import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import Slider from "react-slick";

/* function SampleNextArrow(props) {
  const { onClick } = props;
  return (
  	<div className="owl-nav">
		<div className="owl-next la la-angle-right"  onClick={onClick}/>
	</div>	
  );
}

function SamplePrevArrow(props) {
  const { onClick } = props;
  return (
	<div className="owl-nav">
		<div className=" owl-prev la la-angle-left" onClick={onClick}/>
	</div>
  );
} */

class EventSlider extends Component{	
	render(){
		var settings = {		
			arrows: false,
            slidesToShow: 4,	
			speed: 1500,
			navSpeed: 1500,	
            infinite: true,
			autoplay: true,
			responsive: [
				{
				  breakpoint: 1200,
				  settings: {
					slidesToShow: 4,
				  }
				},
				{
				  breakpoint: 1024,
				  settings: {
					slidesToShow: 3,
				  }
				},
				{
				  breakpoint: 768,
				  settings: {
					slidesToShow: 2,
				  }
				},
				{
				  breakpoint: 576,
				  settings: {
					slidesToShow: 1,
				  }
				}
			]
        };
		return(
			<Slider className="img-carousel-content owl-carousel owl-none" {...settings}>
				<div className="item p-1">
					<div className="box-item-service text-center">
						<div className="item-service-content m-b40">
							<h5>Web Development</h5>
							<p className="m-b0">Learn the most efficient development languages to help you ship quality software.</p>
						</div>
						<div className="clearfix">
							<span className="text-primary round-center"></span>
						</div>
						<div className="icon-bx-md radius border-1 m-t40 m-b20"> 
							<span className="icon-cell"><i className="flaticon-devices"></i></span> 
						</div>
						<Link to={'#'} className="site-button outline outline-2"><span className="font-weight-500">Read More</span></Link>
					</div>
				</div>
				<div className="item p-1">
					<div className="box-item-service text-center">
						<div className="icon-bx-md radius border-1 m-b20 m-t0"> 
							<span className="icon-cell"><i className="flaticon-pen"></i></span> 
						</div>
						<Link to={'#'} className="site-button outline outline-2 m-b40"><span className="font-weight-500">Read More</span></Link>
						<div className="clearfix">
							<span className="text-primary round-center"></span>
						</div>
						<div className="item-service-content m-t40">
							<h5>UI/UX Design</h5>
							<p className="m-b0">With web design, simplicity means perfection. We equip you with the best practices.</p>
						</div>
					</div>
				</div>
				<div className="item p-1">
					<div className="box-item-service text-center">
						<div className="item-service-content m-b40">
							<h5>Mobile Applications</h5>
							<p className="m-b0">Learn from the best in the industry earn the respect,the cash and make impact.</p>
						</div>
						<div className="clearfix">
							<span className="text-primary round-center"></span>
						</div>
						<div className="icon-bx-md radius border-1 m-t40 m-b20"> 
							<span className="icon-cell"><i className="flaticon-smartphone"></i></span> 
						</div>
						<Link to={'#'} className="site-button outline outline-2"><span className="font-weight-500">Read More</span></Link>
					</div>
				</div>
				<div className="item p-1">
					<div className="box-item-service text-center">
						<div className="icon-bx-md radius border-1 m-b20 m-t0"> 
							<span className="icon-cell"><i className="flaticon-team"></i></span> 
						</div>
						<Link to={'#'} className="site-button outline outline-2 m-b40"><span className="font-weight-500">Read More</span></Link>
						<div className="clearfix">
							<span className="text-primary round-center"></span>
						</div>
						<div className="item-service-content m-t40">
							<h5>Digital marketing</h5>
							<p className="m-b0">Learn to use digital tools to sell product effectively and tell product stories to customers.</p>
						</div>
					</div>
				</div>
				<div className="item p-1">
					<div className="box-item-service text-center">
						<div className="item-service-content m-b40">
							<h5>Idea Validation & Rapid prototyping</h5>
							<p className="m-b0">We help you prototype and Validate the sustainability and viability of your business.</p>
						</div>
						<div className="clearfix">
							<span className="text-primary round-center"></span>
						</div>
						<div className="icon-bx-md radius border-1 m-t40 m-b20"> 
							<span className="icon-cell"><i className="flaticon-smartphone"></i></span> 
						</div>
						<Link to={'#'} className="site-button outline outline-2"><span className="font-weight-500">Read More</span></Link>
					</div>
				</div>
				<div className="item p-1">
					<div className="box-item-service text-center">
						<div className="icon-bx-md radius border-1 m-b20 m-t0"> 
							<span className="icon-cell"><i className="flaticon-team"></i></span> 
						</div>
						<Link to={'#'} className="site-button outline outline-2 m-b40"><span className="font-weight-500">Read More</span></Link>
						<div className="clearfix">
							<span className="text-primary round-center"></span>
						</div>
						<div className="item-service-content m-t40">
							<h5>AR/VR/MR/3D/METAVERSE & Movie Animation</h5>
							<p className="m-b0">The next generation of technology is owning a spot in the virtual space.</p>
						</div>
					</div>
				</div>
				
			</Slider>			
		)
		
	}
	
}

export default EventSlider;