import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Header from './../../Layout/Header1';
import Footer from './../../Layout/Footer1';
import PageTitle from './../../Layout/PageTitle';	
// import {OrderDetail} from './../Login';
//Images
import bnr from './../../../images/banner/bnr4.jpg';

//FS
import product1 from './../../../images/product/mobile/fs/flutter.jpg';
import product2 from './../../../images/product/mobile/fs/react.jpg';
import product3 from './../../../images/product/mobile/fs/kotlin.jpg';
import product4 from './../../../images/product/mobile/fs/swift.jpg';

import product5 from './../../../images/product/frontend/fs/react.jpg';
import product6 from './../../../images/product/frontend/fs/vue.jpg';
import product7 from './../../../images/product/frontend/fs/angular.jpg';
import productuiux from './../../../images/product/frontend/fs/uiux.jpg';

import product8 from './../../../images/product/backend/fs/dotnet.jpg';
import product9 from './../../../images/product/backend/fs/golang.jpg';
import product10 from './../../../images/product/backend/fs/nest.jpg';
import product11 from './../../../images/product/backend/fs/php.jpg';
import product12 from './../../../images/product/backend/fs/java.jpg';
import product13 from './../../../images/product/backend/fs/node.jpg';
import product14 from './../../../images/product/backend/fs/python.jpg';
import product15 from './../../../images/product/backend/fs/devops.jpg';

//JD
import productjd1 from './../../../images/product/mobile/jd/flutter.jpg';
import productjd2 from './../../../images/product/mobile/jd/react.jpg';
import productjd3 from './../../../images/product/mobile/jd/kotlin.jpg';
import productjd4 from './../../../images/product/mobile/jd/swift.jpg';

import productjd5 from './../../../images/product/frontend/jd/react.jpg';
import productjd6 from './../../../images/product/frontend/jd/vue.jpg';
import productjd7 from './../../../images/product/frontend/jd/angular.jpg';
import productjduiux from './../../../images/product/frontend/jd/uiux.jpg';

import productjd8 from './../../../images/product/backend/jd/dotnet.jpg';
import productjd9 from './../../../images/product/backend/jd/golang.jpg';
import productjd10 from './../../../images/product/backend/jd/nest.jpg';
import productjd11 from './../../../images/product/backend/jd/php.jpg';
import productjd12 from './../../../images/product/backend/jd/java.jpg';
import productjd13 from './../../../images/product/backend/jd/node.jpg';
import productjd14 from './../../../images/product/backend/jd/python.jpg';
import productjd15 from './../../../images/product/backend/jd/devops.jpg';

//SD
import productsd1 from './../../../images/product/mobile/sd/flutter.jpg';
import productsd2 from './../../../images/product/mobile/sd/react.jpg';
import productsd3 from './../../../images/product/mobile/sd/kotlin.jpg';
import productsd4 from './../../../images/product/mobile/sd/swift.jpg';

import productsd5 from './../../../images/product/frontend/sd/react.jpg';
import productsd6 from './../../../images/product/frontend/sd/vue.jpg';
import productsd7 from './../../../images/product/frontend/sd/angular.jpg';
import productsduiux from './../../../images/product/frontend/sd/uiux.jpg';

import productsd8 from './../../../images/product/backend/sd/dotnet.jpg';
import productsd9 from './../../../images/product/backend/sd/golang.jpg';
import productsd10 from './../../../images/product/backend/sd/nest.jpg';
import productsd11 from './../../../images/product/backend/sd/php.jpg';
import productsd12 from './../../../images/product/backend/sd/java.jpg';
import productsd13 from './../../../images/product/backend/sd/node.jpg';
import productsd14 from './../../../images/product/backend/sd/python.jpg';
import productsd15 from './../../../images/product/backend/sd/devops.jpg';

const itemBox=[
	{image: product1, title:'Mobile Application Development', type: 'Cross (Android/iOS) Platform Technologies with (Flutter)', level: 'Foundation School', price:'GH¢ 500', link:'/programs-details'},
	{image: product2, title:'Mobile Application Development', type: 'Cross (Android/iOS) Platform Technologies with (React Native)', level: 'Foundation School', price:'GH¢ 500', link:'/programs-details'},
	{image: product3, title:'Mobile Application Development', type: 'Native (Android) Platform Technologies with (Kotlin/Java)', level: 'Foundation School', price:'GH¢ 800', link:'/programs-details'},
	{image: product4, title:'Mobile Application Development', type: 'Native (iOS) Platform Technologies with (Swift)', level: 'Foundation School', price:'GH¢ 500', link:'/programs-details'},
	
	{ image: product5, title: 'Web Application Development', type: 'Frontend Technologies with (React.JS)', level: 'Foundation School', price: 'GH¢ 600', link: '/programs-details' },
	{image: product6, title:'Web Application Development', type: 'Frontend Technologies with (Vue.JS)', level: 'Foundation School', price:'GH¢ 600', link:'/programs-details'},
	{ image: product7, title: 'Web Application Development', type: 'Frontend Technologies with (Angular.JS)', level: 'Foundation School', price: 'GH¢ 700', link: '/programs-details' },
	{image: productuiux, title:'UI/UX Engineering', type: 'Design Technologies with (Figma & Sketch)', level: 'Foundation School', price:'GH¢ 600', link:'/programs-details'},
	
	{ image: product8, title: 'Web Backend Application Development (WEB)', type: 'Server Side Technologies with (.NET ASP (C#))', level: 'Foundation School', price: 'GH¢ 800', link: '/programs-details' },
	{image: product9, title:'Web Backend Application Development (WEB)', type: 'Server Side Technologies with (GoLang)', level: 'Foundation School', price:'GH¢ 800', link:'/programs-details'},
	{image: product10, title:'Web Backend Application Development (WEB)', type: 'Server Side Technologies with (Nest.JS)', level: 'Foundation School', price:'GH¢ 600', link:'/programs-details'},
	{image: product11, title:'Web Backend Application Development (WEB)', type: 'Server Side Technologies with (PhP on Symphony)', level: 'Foundation School', price:'GH¢ 700', link:'/programs-details'},
	{image: product12, title:'Web Backend Application Development (WEB)', type: 'Server Side Technologies with (Java on Springboot)', level: 'Foundation School', price:'GH¢ 800', link:'/programs-details'},
	{image: product13, title:'Web Backend Application Development (WEB)', type: 'Server Side Technologies with (Node.JS on Express)', level: 'Foundation School', price: 'GH¢ 600', link: '/programs-details' },
	{ image: product14, title: 'Web Backend Application Development (WEB)', type: 'Server Side Technologies with (Python on Django)', level: 'Foundation School', price: 'GH¢ 700', link: '/programs-details' },
	{image: product15, title:'DevOps Engineering (WEB)', type: 'DevOps', level: 'Foundation School', price:'GH¢ 799', link:'/programs-details'},
];

const juniorDevItemBox=[
	{image: productjd1, title:'Mobile Application Development', type: 'Cross (Android/iOS) Platform Technologies with (Flutter)', level: 'Junior Developer School', price:'GH¢ 799', link:'/programs-details'},
	{image: productjd2, title:'Mobile Application Development', type: 'Cross (Android/iOS) Platform Technologies with (React Native)', level: 'Junior Developer School', price:'GH¢ 799', link:'/programs-details'},
	{image: productjd3, title:'Mobile Application Development', type: 'Native (Android) Platform Technologies with (Kotlin/Java)', level: 'Junior Developer School', price:'GH¢ 999', link:'/programs-details'},
	{image: productjd4, title:'Mobile Application Development', type: 'Native (iOS) Platform Technologies with (Swift)', level: 'Junior Developer School', price:'GH¢ 1,299', link:'/programs-details'},
	
	{ image: productjd5, title: 'Web Application Development', type: 'Frontend Technologies with (React.JS)', level: 'Junior Developer School', price: 'GH¢ 799', link: '/programs-details' },
	{image: productjd6, title:'Web Application Development', type: 'Frontend Technologies with (Vue.JS)', level: 'Junior Developer School', price:'GH¢ 799', link:'/programs-details'},
	{ image: productjd7, title: 'Web Application Development', type: 'Frontend Technologies with (Angular.JS)', level: 'Junior Developer School', price: 'GH¢ 799', link: '/programs-details' },
	{image: productjduiux, title:'UI/UX Engineering', type: 'Design Technologies with (Figma & Sketch)', level: 'Junior Developer School', price:'GH¢ 799', link:'/programs-details'},
	
	{ image: productjd8, title: 'Web Backend Application Development (WEB)', type: 'Server Side Technologies with (.NET ASP (C#))', level: 'Junior Developer School', price: 'GH¢ 1,299', link: '/programs-details' },
	{image: productjd9, title:'Web Backend Application Development (WEB)', type: 'Server Side Technologies with (GoLang)', level: 'Junior Developer School', price:'GH¢ 1,299', link:'/programs-details'},
	{image: productjd10, title:'Web Backend Application Development (WEB)', type: 'Server Side Technologies with (Nest.JS)', level: 'Junior Developer School', price:'GH¢ 999', link:'/programs-details'},
	{image: productjd11, title:'Web Backend Application Development (WEB)', type: 'Server Side Technologies with (PhP on Symphony)', level: 'Junior Developer School', price:'GH¢ 999', link:'/programs-details'},
	{image: productjd12, title:'Web Backend Application Development (WEB)', type: 'Server Side Technologies with (Java on Springboot)', level: 'Junior Developer School', price:'GH¢ 1,299', link:'/programs-details'},
	{image: productjd13, title:'Web Backend Application Development (WEB)', type: 'Server Side Technologies with (Node.JS on Express)', level: 'Junior Developer School', price: 'GH¢ 999', link: '/programs-details' },
	{ image: productjd14, title: 'Web Backend Application Development (WEB)', type: 'Server Side Technologies with (Python on Django)', level: 'Junior Developer School', price: 'GH¢ 1,299', link: '/programs-details' },
	{image: productjd15, title:'DevOps Engineering  (WEB)', type: 'DevOps', level: 'Junior Developer School', price:'GH¢ 999', link:'/programs-details'},
];

const seniorDevItemBox=[
	{image: productsd1, title:'Mobile Application Development', type: 'Cross (Android/iOS) Platform Technologies with (Flutter)', level: 'Senior Developer School', price:'GH¢ 1,000', link:'/programs-details'},
	{image: productsd2, title:'Mobile Application Development', type: 'Cross (Android/iOS) Platform Technologies with (React Native)', level: 'Senior Developer School', price:'GH¢ 1,000', link:'/programs-details'},
	{image: productsd3, title:'Mobile Application Development', type: 'Native (Android) Platform Technologies with (Kotlin/Java)', level: 'Senior Developer School', price:'GH¢ 1,499', link:'/programs-details'},
	{image: productsd4, title:'Mobile Application Development', type: 'Native (iOS) Platform Technologies with (Swift)', level: 'Senior Developer School', price:'GH¢ 1,499', link:'/programs-details'},
	
	{ image: productsd5, title: 'Web Application Development', type: 'Frontend Technologies with (React.JS)', level: 'Senior Developer School', price: 'GH¢ 1,399', link: '/programs-details' },
	{image: productsd6, title:'Web Application Development', type: 'Frontend Technologies with (Vue.JS)', level: 'Senior Developer School', price:'GH¢ 1,399', link:'/programs-details'},
	{ image: productsd7, title: 'Web Application Development', type: 'Frontend Technologies with (Angular.JS)', level: 'Senior Developer School', price: 'GH¢ 1,399', link: '/programs-details' },
	{image: productsduiux, title:'UI/UX Engineering', type: 'Design Technologies with (Figma & Sketch)', level: 'Senior Developer School', price:'GH¢ 799', link:'/programs-details'},
	
	{ image: productsd8, title: 'Web Backend Application Development (WEB)', type: 'Server Side Technologies with (.NET ASP (C#))', level: 'Senior Developer School', price: 'GH¢ 1,299', link: '/programs-details' },
	{image: productsd9, title:'Web Backend Application Development (WEB)', type: 'Server Side Technologies with (GoLang)', level: 'Senior Developer School', price:'GH¢ 1,299', link:'/programs-details'},
	{image: productsd10, title:'Web Backend Application Development (WEB)', type: 'Server Side Technologies with (Nest.JS)', level: 'Senior Developer School', price:'GH¢ 999', link:'/programs-details'},
	{image: productsd11, title:'Web Backend Application Development (WEB)', type: 'Server Side Technologies with (PhP on Symphony)', level: 'Senior Developer School', price:'GH¢ 999', link:'/programs-details'},
	{image: productsd12, title:'Web Backend Application Development (WEB)', type: 'Server Side Technologies with (Java on Springboot)', level: 'Senior Developer School', price:'GH¢ 1,299', link:'/programs-details'},
	{image: productsd13, title:'Web Backend Application Development (WEB)', type: 'Server Side Technologies with (Node.JS on Express)', level: 'Senior Developer School', price: 'GH¢ 999', link: '/programs-details' },
	{ image: productsd14, title: 'Web Backend Application Development (WEB)', type: 'Server Side Technologies with (Python on Django)', level: 'Senior Developer School', price: 'GH¢ 1,299', link: '/programs-details' },
	{image: productsd15, title:'DevOps Engineering  (WEB)', type: 'DevOps with (Docker,AWS, etc)', level: 'Senior Developer School', price:'GH¢ 1,999', link:'/programs-details'},
];

// const specialProgramsItemBox=[
// 	{image: product1, title:'Digital Marketing', type: 'Complete Digital Marketing Program', level: 'Complete', price:'GH¢ 1,000', link:'/programs-details'},
// 	{image: product2, title:'Animation', type: 'Character Animation', level: 'Complete', price:'GH¢ 1,499', link:'/programs-details'},
// 	{image: product3, title:'Graphics Design', type: 'Motion Graphics, Logo Design, Conceptual Graphics', level: 'Complete', price:'GH¢ 1,000', link:'/programs-details'},
// 	{image: product5, title:'Videography', type: 'Music Video Shoot, Movie Shoot, Video Directing', level: 'Complete', price:'GH¢ 1,000', link:'/programs-details'},
// 	{image: product6, title: 'Photography', type: 'Complete Photography Program', level: 'Complete', price: 'GH¢ 1,000', link: '/programs-details' },
// 	{image: product4, title:'IoT', type: 'Robotics For Kids', level: 'Kids', price:'GH¢ 500', link:'/programs-details'},
// ];

class ShopColumns extends Component {
    render() {
        return (
            <>
                <Header />
                <div className="page-content bg-white">
                    {/* <!-- inner page banner --> */}
                    <div className="dlab-bnr-inr overlay-primary bg-pt" style={{ backgroundImage: "url(" + bnr + ")" }}>
						<PageTitle motherMenu="Upcoming Programs"  activeMenu="Upcoming Programs"  />
                    </div>
                    {/* <!-- inner page banner END --> */}
                    {/* <!-- contact area --> */}
                    <div className="section-full content-inner">
                        {/* <!-- Product --> */}
						<div className="container">
							<p><h4>Foundation School Programs:</h4></p>
                            <div className="row">
								{itemBox.map((data,index)=>(
									<div className="col-lg-3 col-md-6 col-sm-6">
										<div className="item-box m-b10">
											<div className="item-img">
												<img src={data.image} alt="" />
												<div className="item-info-in">
													<ul>
														<li><Link to={"#"}><i className="fa fa-facebook"></i></Link></li>
														<li><Link to={"#"}><i className="fa fa-twitter"></i></Link></li>
														<li><Link to={"#"}><i className="fa fa-instagram"></i></Link></li>
														<li><Link to={"#"}><i className="fa fa-linkedin"></i></Link></li>
													</ul>
												</div>
											</div>
											<div className="item-info text-center text-black p-a10">
												<h6 className="item-title font-weight-500"><Link to={ data.link }>{data.title}</Link></h6>
												<h7 className="item-title font-weight-500"><Link to={ data.link }>{data.level}</Link></h7>
												<ul className="item-review">
													<li>{ data.type }</li>
												</ul>
												<h4 className="item-price"><span className="text-primary">{ data.price }</span></h4>
											</div>
										</div>
									</div>
								))}
							</div>
							<p><h4>Junior Developer School (Level 2) Programs:</h4></p>
							<div className="row">
							{juniorDevItemBox.map((data,index)=>(
									<div className="col-lg-3 col-md-6 col-sm-6">
										<div className="item-box m-b10">
											<div className="item-img">
												<img src={data.image} alt="" />
												<div className="item-info-in">
													<ul>
														<li><Link to={"#"}><i className="fa fa-facebook"></i></Link></li>
														<li><Link to={"#"}><i className="fa fa-twitter"></i></Link></li>
														<li><Link to={"#"}><i className="fa fa-instagram"></i></Link></li>
														<li><Link to={"#"}><i className="fa fa-linkedin"></i></Link></li>
													</ul>
												</div>
											</div>
											<div className="item-info text-center text-black p-a10">
												<h6 className="item-title font-weight-500"><Link to={ data.link }>{data.title}</Link></h6>
												<h7 className="item-title font-weight-500"><Link to={ data.link }>{data.level}</Link></h7>
												<ul className="item-review">
													<li>{ data.type }</li>
												</ul>
												<h4 className="item-price"><span className="text-primary">{ data.price }</span></h4>
											</div>
										</div>
									</div>
								))}
							</div>
							<p><h4>Senior Developer School (Skill Accelerator) Programs:</h4></p>
							<div className="row">
							{seniorDevItemBox.map((data,index)=>(
									<div className="col-lg-3 col-md-6 col-sm-6">
										<div className="item-box m-b10">
											<div className="item-img">
												<img src={data.image} alt="" />
												<div className="item-info-in">
													<ul>
														<li><Link to={"#"}><i className="fa fa-facebook"></i></Link></li>
														<li><Link to={"#"}><i className="fa fa-twitter"></i></Link></li>
														<li><Link to={"#"}><i className="fa fa-instagram"></i></Link></li>
														<li><Link to={"#"}><i className="fa fa-linkedin"></i></Link></li>
													</ul>
												</div>
											</div>
											<div className="item-info text-center text-black p-a10">
												<h6 className="item-title font-weight-500"><Link to={ data.link }>{data.title}</Link></h6>
												<h7 className="item-title font-weight-500"><Link to={ data.link }>{data.level}</Link></h7>
												<ul className="item-review">
													<li>{ data.type }</li>
												</ul>
												<h4 className="item-price"><span className="text-primary">{ data.price }</span></h4>
											</div>
										</div>
									</div>
								))}
							</div>
							{/* <p><h4>Special Programs:</h4></p>
							<div className="row">
							{specialProgramsItemBox.map((data,index)=>(
									<div className="col-lg-3 col-md-6 col-sm-6">
										<div className="item-box m-b10">
											<div className="item-img">
												<img src={data.image} alt="" />
												<div className="item-info-in">
													<ul>
														<li><Link to={"#"}><i className="fa fa-facebook"></i></Link></li>
														<li><Link to={"#"}><i className="fa fa-twitter"></i></Link></li>
														<li><Link to={"#"}><i className="fa fa-instagram"></i></Link></li>
														<li><Link to={"#"}><i className="fa fa-linkedin"></i></Link></li>
													</ul>
												</div>
											</div>
											<div className="item-info text-center text-black p-a10">
												<h6 className="item-title font-weight-500"><Link to={ data.link }>{data.title}</Link></h6>
												<h7 className="item-title font-weight-500"><Link to={ data.link }>{data.level}</Link></h7>
												<ul className="item-review">
													<li>{ data.type }</li>
												</ul>
												<h4 className="item-price"><span className="text-primary">{ data.price }</span></h4>
											</div>
										</div>
									</div>
								))}
							</div> */}
                        </div>
                        {/* <!-- Product END --> */}
                    </div>
                    { /* <-- Icon Blog and Order detail--> */}
						{/* <OrderDetail /> */}
					{/* <-- Icon Blog and Order detail End--> */}
                </div>

                <Footer />
            </>
        )
    }
}
export default ShopColumns;