import React, {Component} from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import ThemeButton from './Element/ThemeButton/ThemeButton';

import Homepage from './Pages/Homepage1';
import Index2 from './Pages/Index2';
import Index3 from './Pages/Index3';
import Index4 from './Pages/Index4';
import Index5 from './Pages/Index5';
import Index6 from './Pages/Index6';
import Index7 from './Pages/Index7';
import Aboutus1 from './Pages/Aboutus/Aboutus1';
import Aboutus2 from './Pages/Aboutus/Aboutus2';
import Error403 from './Pages/Error/Error403';
import Error404 from './Pages/Error/Error404';
import Error405 from './Pages/Error/Error405';
import ComingSoon1 from './Pages/ComingSoon/ComingSoon1';
import UnderMaintenance from './Pages/UnderMaintenance';
import Expertise from './Pages/Expertise';
import ProjectManagement from './Pages/ProjectManagement';
import OurProjects from './Pages/OurProjects';
import ProjectDetails from './Pages/ProjectDetails';
import Team from './Pages/Team';
import Faqs from './Pages/Faqs';
import Login from './Pages/Login';
import Register from './Pages/Register';
import Contact from './Pages/Contact';
import Application from './Pages/application';
import Service from './Pages/Service/Service';
import ServicesDetails from './Pages/Service/ServicesDetails';
import BlogStandard from './Pages/BlogStandard/BlogStandard';
import BlogStandardLeftSidebar from './Pages/BlogStandard/BlogStandardLeftSidebar';
import BlogStandardRightSidebar from './Pages/BlogStandard/BlogStandardRightSidebar';
import BlogClassicGrid from './Pages/BlogClassic/BlogClassicGrid';
import BlogClassicLeftSidebar from './Pages/BlogClassic/BlogClassicLeftSidebar';
import BlogClassicRightSidebar from './Pages/BlogClassic/BlogClassicRightSidebar';
import BlogListLeftSidebar from './Pages/BlogList/BlogListLeftSidebar';
import BlogListRightSidebar from './Pages/BlogList/BlogListRightSidebar';
import BlogDetails from './Pages/BlogDetail/BlogDetails';
import ProtfolioFullWidth from './Pages/Protfolio/ProtfolioFullWidth';
import PortfolioGrid2 from './Pages/Protfolio/PortfolioGrid2';
import PortfolioGrid3 from './Pages/Protfolio/PortfolioGrid3';
import PortfolioGrid4 from './Pages/Protfolio/PortfolioGrid4';
import ShopColumns from './Pages/Shop/ShopColumns';
import ShopColumnsSidebar from './Pages/Shop/ShopColumnsSidebar';

import ShopCart from './Pages/Shop/ShopCart';
import ShopCheckout from './Pages/Shop/ShopCheckout';	
import ShopProductDetails from './Pages/Shop/ShopProductDetails';	

import ShopWishlist from './Pages/Shop/ShopWishlist';
import ShopLogin from './Pages/Shop/ShopLogin';
import ShopRegister from './Pages/Shop/ShopRegister';

import SpecialPrograms from './Pages/Shop/SpecialPrograms';
import FoundationSchool from './Pages/Shop/FoundationSchool';
import JuniorDevSchool from './Pages/Shop/JuniorDevSchool';
import SeniorDevSchool from './Pages/Shop/SeniorDevSchool';

import ShortIconBox from './Pages/ShortCode/ShortIconBox';
import ShortCounter from './Pages/ShortCode/ShortCounter';
import ShortPortfolio from './Pages/ShortCode/ShortPortfolio';
import ShortTabs from './Pages/ShortCode/ShortTabs';
import ShortTeam from './Pages/ShortCode/ShortTeam';
import ShortTestimonial from './Pages/ShortCode/ShortTestimonial';
import ShortForm from './Pages/ShortCode/ShortForm';
import ShortAccordions from './Pages/ShortCode/ShortAccordions';
import HelmetMetaData from './HelmetMetaData';

import ScrollToTop from './Element/ScrollToTop';

class Markup extends Component{
	render(){
		return(
			<BrowserRouter basename="/">
                <div className="page-wraper">
                <HelmetMetaData></HelmetMetaData>
                    <Switch>
                        <Route exact path='/'  component={Homepage} />
                        <Route path='/index-2'  component={Index2} />
                        <Route path='/index-3'  component={Index3} />
                        <Route path='/index-4'  component={Index4} />
                        <Route path='/index-5'  component={Index5} />
                        <Route path='/index-6'  component={Index6} />
                        <Route path='/index-7'  component={Index7} />
                        <Route path='/about'  component={Aboutus1} />
                        <Route path='/about-2'  component={Aboutus2} />
                        <Route path='/error-403'  component={Error403} />
                        <Route path='/error-404'  component={Error404} />
                        <Route path='/error-405'  component={Error405} />
                        <Route path='/coming-soon-1'  component={ComingSoon1} />
                        <Route path='/under-maintenance'  component={UnderMaintenance} />
                        <Route path='/expertise'  component={Expertise} />
                        <Route path='/our-process'  component={ProjectManagement} />
                        <Route path='/our-projects'  component={OurProjects} />
                        <Route path='/project-details'  component={ProjectDetails} />
                        <Route path='/team'  component={Team} />
                        <Route path='/faqs'  component={Faqs} />
                        <Route path='/login'  component={Login} />
                        <Route path='/register'  component={Register} />
                        <Route path='/contact' component={Contact} />
                        <Route path='/apply'  component={Application} />
                        <Route path='/service'  component={Service} />
                        <Route path='/services-details'  component={ServicesDetails} />
                        <Route path='/blog-standard'  component={BlogStandard} />
                        <Route path='/blog-standard-left-sidebar'  component={BlogStandardLeftSidebar} />
                        <Route path='/blog-standard-right-sidebar'  component={BlogStandardRightSidebar} />
                        <Route path='/blog-classic-grid'  component={BlogClassicGrid} />
                        <Route path='/blog-classic-left-sidebar'  component={BlogClassicLeftSidebar} />
                        <Route path='/blog-classic-right-sidebar'  component={BlogClassicRightSidebar} />
                        <Route path='/news'  component={BlogListLeftSidebar} />
                        <Route path='/blog-list-right-sidebar'  component={BlogListRightSidebar} />
                        <Route path='/blog-details'  component={BlogDetails} />
                        <Route path='/portfolio'  component={ProtfolioFullWidth} />
                        <Route path='/portfolio-grid-2'  component={PortfolioGrid2} />
                        <Route path='/portfolio-grid-3'  component={PortfolioGrid3} />
                        <Route path='/portfolio-grid-4'  component={PortfolioGrid4} />
                        <Route path='/programs' component={ShopColumns} />
                        
                        <Route path='/programs-sidebar'  component={ShopColumnsSidebar} />
						
                        <Route path='/shop-cart'  component={ShopCart} />
                        <Route path='/shop-checkout'  component={ShopCheckout} />
                        <Route path='/programs-details' component={ShopProductDetails} />
                        
                        <Route path='/foundation-school/:id' component={ShopProductDetails} />
                        <Route path='/junior-dev-school/:id' component={ShopProductDetails} />
                        <Route path='/senior-dev-school/:id' component={ShopProductDetails} />
                        
                        <Route path='/shop-wishlist'  component={ShopWishlist} />
                        <Route path='/shop-login'  component={ShopLogin} />
                        <Route path='/shop-register'  component={ShopRegister} />
                        <Route path='/special-programs'  component={SpecialPrograms} />
                        <Route path='/foundation-school'  component={FoundationSchool} />
                        <Route path='/junior-dev-school'  component={JuniorDevSchool} />
                        <Route path='/senior-dev-school'  component={SeniorDevSchool} />
						
                        <Route path='/short-icon-box'  component={ShortIconBox} />
                        <Route path='/short-counter'  component={ShortCounter} />
                        <Route path='/short-portfolio'  component={ShortPortfolio} />
                        <Route path='/short-tabs'  component={ShortTabs} />
                        <Route path='/team'  component={ShortTeam} />
                        <Route path='/short-testimonial'  component={ShortTestimonial} />
                        <Route path='/short-form'  component={ShortForm} />
                        <Route path='/short-accordions'  component={ShortAccordions} />						
					</Switch>
                </div>
				<ScrollToTop />
				<ThemeButton />
            </BrowserRouter>	
		)
	}
	
}

export default Markup;