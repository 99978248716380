import React, { Component } from "react";
import SimpleReactLightbox from 'simple-react-lightbox';
import {SRLWrapper} from 'simple-react-lightbox'; 
import Slider from "react-slick";

import jsonData from './../../../data/seniorDevPrograms';
import juniorDevJsonData from './../../../data/juniorDevPrograms';
import foundationDevJsonData from './../../../data/foundationPrograms';

export default class ShopProductSlider extends Component {
	constructor(props) {
		super(props);
		this.state = {
			nav1: null,
			nav2: null,
			item: {}
		};
    }

	componentDidMount() {
		console.log("componentDidMount");
		this.getInitialState();
		this.setState({
			nav1: this.slider1,
			nav2: this.slider2
		});
	}

	getInitialState = () => {
		var selectedType = localStorage.getItem('SelectedOptionType');
		if (selectedType === 'juniorDevSchool') {
			var selectedOption = localStorage.getItem('SelectedOption') || 1;
			juniorDevJsonData.map((item) => {
				if (item.id === parseInt(selectedOption)) {
					this.setState({
						item: item,
					});
				}
			});
			return {
				selectedOption: selectedOption
			};
		}

		if (selectedType === 'seniorDevSchool') {
			var selectedOption = localStorage.getItem('SelectedOption') || 1;
			jsonData.map((item) => {
				if (item.id === parseInt(selectedOption)) {
					this.setState({
						item: item,
					});
				}
			});
			return {
				selectedOption: selectedOption
			};
		}

		if (selectedType === 'foundationSchool') {
			var selectedOption = localStorage.getItem('SelectedOption') || 1;
			foundationDevJsonData.map((item) => {
				if (item.id === parseInt(selectedOption)) {
					this.setState({
						item: item,
					});
				}
			});
			return {
				selectedOption: selectedOption
			};
		}
    }

	render() {
		var settings = {		
			arrows: false,
			centerMode: false,
			
        };	
		return (
			<div>
				<SimpleReactLightbox>
					<SRLWrapper >
						<Slider
							asNavFor={this.state.nav2}
							ref={slider => (this.slider1 = slider)}
							className="owl-carousel owl-theme owl-btn-center-lr m-b5 owl-btn-1 primary"
							{...settings}
						>	
					
							<div>
								<div className="item ">
									<div className="mfp-gallery">
										<div className="dlab-box">
											<div className="dlab-thum-bx dlab-img-overlay1 ">
												{console.log("::::::::::",this.state.item.image)}
												<img src={this.state.item.image} alt="" style={{width:"100%"}} />
											</div>
										</div>
									</div>
								</div>
							</div>
						</Slider>
					</SRLWrapper >			
				</SimpleReactLightbox>
			</div>
		);
	}
}